/*------------------------------------*\
		CSS Reset

	http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
\*------------------------------------*/

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,
a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,
small,strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,canvas,details,embed,
figure,figcaption,footer,header,hgroup,
menu,nav,output,ruby,section,summary,
time,mark,audio,video,
button,input,select {
	vertical-align: baseline;
	margin: 0;
	padding: 0;
	font: inherit;
	font-size: 100%;
	border: 0;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

q,
blockquote {
	quotes: none;

	&:after,
	&:before {
		content: "";
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button,
input {
	font: inherit;

	.mac & {
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-moz-osx-font-smoothing: antialiased;
	}
}

button {
	cursor: pointer;
}
/*=============================================
=            Generic styling                  =
=============================================*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  background-color: #333;
  overflow-x: hidden;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

p {
  margin-top: 0;
}

hr {
  display: block;
  margin: 30px 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 400;
  line-height: 1.2;
}

a,
a:visited,
a:focus {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
}

.form-control {
  display: block;
  width: 100%;
  background-color: #f9f9f9;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  margin-bottom: 24px;
}

h1,
.h1 {
  font-size: 32px;
}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
}

p {
  margin-bottom: 8px;
}

label + p {
  margin-top: -4px;
}

.container {
  display: block;
  margin: auto;
  max-width: 40em;
  padding: 48px;
}

@media (max-width: 620px) {
  .container {
    padding: 0;
  }
}

.section {
  background-color: #ffffff;
  padding: 24px;
  color: #333;
}

.section a,
.section a:visited,
.section a:focus {
  color: #00bcd4;
}

.logo {
  display: block;
  margin-bottom: 12px;
}

.logo__img {
  width: 100%;
  height: auto;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  padding: 6px 0;
}

.visible-ie {
  display: none;
}

.push-bottom {
  margin-bottom: 24px;
}

.zero-bottom {
  margin-bottom: 0;
}

.zero-top {
  margin-top: 0;
}

.text-center {
  text-align: center;
}

[data-test-hook] {
  margin-bottom: 24px;
}

/*=====  End of Section comment block  ======*/

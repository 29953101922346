#main-menu {
    @include autoAlpha(0);
    @include full-fixed;
    z-index: 10000;

    .color-bg {
        @include full-absolute;
        background-color: $blue-1;
        transform-origin: 0 100%;
        transform: scaleY(0);
        z-index: 1;
    }

    .background {
        z-index: 2;
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            left: auto;
            @include set-grid-prop(right, -0.5, 0);
            transform: translate(0, -50%);

            svg {
                width: 652px;
                height: 1137px;
            }
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate(calc(-50px * #{$i}), -50%);
                }
            }
        }
    }

    .main-menu__top {
        position: absolute;
        width: 100%;
        z-index: 40;

        .logo {
            position: absolute;
            top: 30px;
            left: -20px;
            background-color: rgba(0, 0, 0, 0.3);
            height: 80px;
            padding: 0 30px 0 50px;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            transition: transform 0.6s $easeInOutQuart;

            svg {
                width: 188px;
                height: auto;

                &.mobile {
                    display: none;
                    width: 126px;
                    height: auto;
                }
            }

            html.desktop & {
                &:hover {
                    transform: translate(20px, 0);
                }
            }
        }

        .languages {
            position: absolute;
            top: 60px;
            left: 300px;
            z-index: 10;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    padding: 0 18px;
                    padding-top: 2px;
                    border-left: 1px solid rgba(151, 151, 151, 0.4);

                    &:first-child {
                        padding-left: 0;
                        border-left: none;
                    }

                    a {
                        @include armin-grotesk-regular(16px, 16px);
                        color: white;
                        opacity: 0.5;
                        transition: opacity 0.6s $easeOutQuart;
                        text-transform: uppercase;

                        &.active {
                            opacity: 1;
                        }

                        html.desktop & {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .menu {
            position: absolute;
            right: 0;
            top: 30px;
            height: 80px;
            z-index: 100;

            .contact-button {
                position: absolute;
                right: -12px;
                top: 0;
                padding: 0 30px;
                height: 80px;
                background-color: rgba(0, 0, 0, 0.3);
                border-top-left-radius: 40px;
                border-bottom-left-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: right 0.6s $easeInOutQuart;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 36px;
                    height: 36px;
                }

                .text {
                    padding-top: 4px;
                    margin-left: 20px;
                    text-transform: uppercase;
                    @include armin-grotesk-regular(13px, 13px);
                    color: white;
                }

                html.desktop & {
                    &:hover {
                        right: 80px;
                    }
                }
            }

            .close-button {
                cursor: pointer;
                width: 80px;
                height: 80px;
                background-color: $red;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                svg {
                    width: 26px;
                    height: 26px;
                    transition: transform 0.6s $easeInOutQuart;
                }

                html.desktop & {
                    &:hover {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }

            &.disabled {

                .contact-button {
                    right: -92px;

                    html.desktop & {
                        &:hover {
                            right: 0;
                        }
                    }
                }

                .open-button {
                    display: none;
                }
            }
        }
    }

    .main-menu__content {
        padding-left: 8.5vw;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        z-index: 4;

        ul {

            li {
                @include armin-grotesk-black(36px, 46px);
                text-transform: uppercase;
                margin-top: 30px;

                span {
                    color: $white;
                    transition: color 0.6s $easeOutQuart;
                }

                html.desktop & {
                    &:hover {
                        span {
                            color: $green;
                        }
                    }
                }

                /*&.current-menu-item {
                    span {
                        color: $white;
                    }
                }*/
            }
        }
    }

    .main-menu__socials {
        position: absolute;
        bottom: 10vh;
        right: 10vw;
        z-index: 4;

        ul {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                width: 70px;
                height: 70px;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    &:before {
                        pointer-events: none;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: white;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: 1;
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    svg {
                        z-index: 2;
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }
                }

                &.instagram {
                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }

                &.linkedin {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.youtube {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .background {
            display: none;
        }

        .main-menu__top {
            background-color: rgba(0, 0, 0, 0.3);
            height: 80px;

            .logo {
                top: 50%;
                left: 20px;
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                transform: translate(0, -50%);

                svg {
                    &.desktop {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }
                }
            }

            .languages {
                left: 8.5vw;
                top: 110px;
            }

            .menu {
                top: 0;

                .contact-button {
                    background-color: transparent;
                    border-radius: 0;
                    border-left: 1px solid rgba(255, 255, 255, 0.2);
                    padding-left: 20px;

                    svg {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }

        .main-menu__content {

            ul {
                justify-content: center;

                li {
                    margin-top: 20px;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .main-menu__socials {
            left: 8.5vw;

            ul {
                li {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}
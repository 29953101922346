.section-highlight {
    background-color: $blue-1;
    padding: 200px 0;

    /*
    + .section-numbers {
        padding-top: 0px;
    }
    */

    .background {
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        width: 597px;
        height: 540px;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 0;
            left: 0;

            svg {
                width: 597px;
                height: 540px;
            }
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(-20px * #{$i}), 0, 0.1px);
                }
            }
        }
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        .column-4 {
            @include set-grid-prop(width, 4, 4);

            .title {
                @include armin-grotesk-black(36px, 36px);
                color: $green;
                text-transform: uppercase;
            }

            .content {
                margin-top: 30px;
                margin-left: 40px;
                @include armin-grotesk-semi-bold(19px, 31px);
                color: white;

                ul {
                    padding-left: 20px;

                    li {
                        position: relative;
                        margin-top: 10px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:before {
                            content: "";
                            background-color: white;
                            width: 6px;
                            height: 6px;
                            position: absolute;
                            left: -20px;
                            top: 12px;
                            border-radius: 50%;
                            z-index: 10;
                        }
                    }
                }
            }

            .button {
                margin-top: 50px;
                margin-left: 40px;
            }

            &.c-1 {
                @include set-grid-prop(margin-left, 1, 2);
            }

            &.c-2 {
                @include set-grid-prop(margin-left, 1, 1);
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .background {
            transform: translate(-50%, -50%) scale(0.72);
            opacity: 0.4;
        }

        .r-1 {

            .column-4 {

                .title {
                    font-size: 20px;
                    line-height: 25px;
                }

                .content {
                    margin-left: 0;
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 22px;
                }

                .button {
                    margin-top: 20px;
                    margin-left: 0;
                }

                &.c-2 {
                    margin-top: 40px;
                }
            }
        }
    }
}
.section-expertise {
    background-color: $blue-1;
    padding: 100px 0;
    z-index: 2;

    .r-1 {
        padding-top: calc(var(--aspect-ratio)*90%);

        .video-container {
            background-color: $purple;
            cursor: none;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 2;
            @include set-grid-prop(width, 11, 10);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

            .button-play {
                pointer-events: none;
                display: none;
                @include center-xy;
                border-radius: 50%;
                background-color: $yellow;
                align-items: center;
                justify-content: center;
                z-index: 10;
                width: 60px;
                height: 60px;

                svg {
                    width: 18px;
                    height: auto;
                    fill: white;
                }
            }

            a {
                display: block;
            }

            img,
            video {
                position: absolute;
                left: 0;
                top: 0;
            }

            img {
                display: none;
                z-index: 1;
            }

            video {
                z-index: 2;
            }

            #full {
                opacity: 0;
                z-index: 10;
            }
        }
    }

    .r-2 {
        margin-top: 100px;

        .column-12 {

            .title {
                @include armin-grotesk-black(70px, 80px);
                text-transform: uppercase;
                color: $white;
                margin-bottom: 80px;

                .l-3,
                .l-1 {
                    @include set-grid-prop(margin-left, 0, 1);
                }

                .l-2 {
                    @include set-grid-prop(margin-left, 1, 1);
                }

                .outline {
                    -webkit-text-stroke-color: $white;
                }
            }
        }

        .column-4 {
            float: right;
            margin-top: -50px;
            @include set-grid-prop(margin-right, 1, 1);
            //@include set-grid-prop(width, 4, 4);
            @include armin-grotesk-semi-bold(26px, 36px);
            color: $white;

            .button {
                margin-top: 40px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 50px 0;

        .r-1 {
            padding-top: calc(var(--aspect-ratio)*100%);

            .video-container {
                transform: translate(-50%, 0);

                .button-play {
                    display: flex;
                }

                img {
                    display: block;
                }
            }
        }

        .r-2 {
            margin-top: 50px;

            .column-12 {

                .title {
                    font-size: 30px;
                    line-height: 35px;
                    margin-bottom: 30px;

                    .l-1 {
                        margin-left: 20px;
                    }

                    .l-2 {
                        margin-left: 50px;
                    }
                }
            }

            .column-4 {
                margin-top: 30px;
                font-size: 16px;
                line-height: 24px;
                padding-left: 90px;
            }
        }
    }
}
.section-intro {
    position: relative;
    //background-color: $blue-1;
    height: 100vh;
    width: 100%;
    z-index: 1;

    .background {
        @include full-absolute;
        background: $blue-1;
        pointer-events: none;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .section__inner {
        position: relative;
        z-index: 2;
    }

    .grid,
    .row,
    .column-12 {
        height: 100%;
        z-index: 3;
        transform: translate3d(0, 0, 0.1px);
    }

    h1 {
        @include armin-grotesk-black(44px, 54px);
        @include set-grid-prop(margin-left, 1, 1);
        color: $white;
        text-transform: uppercase;
        padding-top: 45vh;
        z-index: 10;
    }

    /*h2, h3 {
        @include armin-grotesk-black(36px, 46px);
        @include set-grid-prop(margin-left, 1, 1);
        color: $white;
        text-transform: none;
        z-index: 10;
    }*/

    .subtitle p {
        margin-top: 20px;
        @include armin-grotesk-semi-bold(26px, 36px);
        @include set-grid-prop(margin-left, 1, 1);
        color: $white;
        text-transform: none;
        z-index: 10;
    }

    .line p {
        margin-top: 20px;
        @include armin-grotesk-semi-bold(26px, 36px);
        @include set-grid-prop(margin-left, 1, 1);
        color: $white;
        text-transform: none;
        z-index: 10;
    }

    .button {
        margin-top: 40px;
        @include set-grid-prop(margin-left, 1, 1);
    }

    @media screen and (max-width: $mobile) {
        height: 90vh;
        background-color: $blue-1;

        /*
        .background {
            display: none;
        }
        */

        h1 {
            padding-top: 270px;
            margin-left: 20px;
            font-size: 25px;
            line-height: 30px;
        }
        
        /*h2, h3 {
            @include armin-grotesk-black(20px, 25px);
            margin-left: 20px;
        }*/

        .button {
            margin-left: 20px;
        }
    }
}
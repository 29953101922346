.section-partners {
    background-color: $white;
    padding: 100px 0;

    .r-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .button {
            margin-left: auto;
            @include set-grid-prop(margin-right, 1, 1);
        }

        [class^="column"] {
            float: none;
        }

        h2 {
            color: $blue-1;

            span {
                display: block;
            }

            .l-1 {
               @include set-grid-prop(margin-left, 1, 0);
            }

            .l-2 {
                @include set-grid-prop(margin-left, 1, 1);
            }
        }
    }

    .r-2 {
        margin-top: 80px;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        ul {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            li {
                margin-left: 80px;
                flex: 0 0 auto;

                img {
                    max-height: 50px;
                    width: auto;
                }

                &:first-child {
                    margin-left: 0;
                }

                a {
                    display: block;
                }
            }
        }

        .p-2 {
            margin-top: 80px;
        }

    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-1 {

            h2 {
                .l-1 {
                    margin-left: 0;
                }

                .l-2 {
                    margin-left: 0;
                }
            }
        }

        .r-2 {
            margin-top: 40px;

            ul {

                li {
                    margin-left: 40px;
                }
            }

            .p-2 {
                margin-top: 20px;
            }
        }
    }
}

/**
LOOP SLIDER
 */
.loop-slider {
    position: relative;
    display: flex;

    &.reverse {
        flex-direction: row-reverse;

        .loop-slider-wrapper {
            transform: translate(calc(100% / 3), 0);

            .loop-item {
                margin-right: 80px;
                margin-left: 0;
            }
        }
    }

    .loop-slider-wrapper {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        transform: translate(calc(-100% / 3), 0);

        .loop-slider-group {
            display: inline-flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        .loop-slider-item {
            flex: 1 0 auto;
            position: relative;
            margin-left: 80px;
            text-transform: uppercase;
            white-space: nowrap;
            height: 50px;
            width: 140px;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
}

#single, #page {
    background-color: $blue-1;

    .block-image + .block-text {
        padding-top: 0;
    }

    .block.block-text {

        h2 {
            margin-top: 60px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .section-head {
        height: 650px;
        //max-height: 100vh;
        padding-bottom: 10vh;

        .grid,
        .row {
            height: 100%;
        }

        .row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
        }

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .background {
            @include full-absolute;
            z-index: 1;
            overflow: hidden;

            &:after {
                @include full-absolute;
                opacity: 0.5;
                content: "";
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                z-index: 2;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .row {
            z-index: 2;
        }

        .tags {
            margin-top: 20px;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-left: -14px;

                li {
                    margin-left: 14px;
                    margin-bottom: 14px;
                    border-radius: 20px;
                    height: 40px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    transition: background-color 0.6s $easeOutQuart;

                    /*html.desktop & {
                        &:hover {
                            background-color: rgba(255, 255, 255, 0.2);
                        }
                    }*/

                    .color {
                        width: 8px;
                        height: 8px;
                        background-color: white;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .text {
                        @include armin-grotesk-black(14px, 24px);
                        color: white;
                        padding-top: 4px;
                    }
                }
            }
        }

        .metas {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include armin-grotesk-black(18px, 28px);
            color: $white;

            .type {
                text-transform: uppercase;
            }

            .date {
                margin-left: 20px;
            }
        }

        h1 {
            margin-top: 20px;
            @include armin-grotesk-black(44px, 54px);
            color: white;
            text-transform: uppercase;
        }

        .introduction {
            margin-top: 30px;
            @include armin-grotesk-semi-bold(26px, 36px);
        }

        .button {
            margin-top: 40px;
        }
    }

    .section-book-head {
        background-color: $purple;
        padding: 70px 0;
        //max-height: 100vh;

        .grid,
        .row {
            height: 100%;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            [class^="column"] {
                float: none;
            }
        }

        .column-5 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .column-3 {
            margin-left: auto;
            @include set-grid-prop(margin-right, 1.5, 2);
        }

        .tags {
            margin-top: 20px;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-left: -14px;

                li {
                    margin-left: 14px;
                    margin-bottom: 14px;
                    border-radius: 20px;
                    height: 40px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    transition: background-color 0.6s $easeOutQuart;

                    /*html.desktop & {
                        &:hover {
                            background-color: rgba(255, 255, 255, 0.2);
                        }
                    }*/

                    .color {
                        width: 8px;
                        height: 8px;
                        background-color: white;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .text {
                        @include armin-grotesk-black(14px, 24px);
                        color: white;
                        padding-top: 4px;
                    }
                }
            }
        }

        .metas {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include armin-grotesk-black(18px, 28px);
            color: $white;

            .type {
                text-transform: uppercase;
            }

            .date {
                margin-left: 20px;
            }
        }

        h1 {
            margin-top: 20px;
            @include armin-grotesk-black(44px, 54px);
            color: white;
            text-transform: uppercase;
        }

        .introduction {
            margin-top: 30px;
            @include armin-grotesk-semi-bold(26px, 36px);
            max-width: 438px;
        }

        .button {
            margin-top: 40px;
        }
    }

    .section-share {
        padding-bottom: 100px;

        .column-10 {
            @include set-grid-prop(margin-left, 2, 2);
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .legend {
            @include armin-grotesk-black(20px, 20px);
            color: white;
            text-transform: uppercase;
        }

        ul {
            margin-left: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                width: 70px;
                height: 70px;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    &:before {
                        pointer-events: none;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: white;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: 1;
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    svg {
                        z-index: 2;
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }
                }

                &.twitter {
                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }

                &.linkedin {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.mail {
                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }
            }
        }
    }

    .section-share.color-bkg-white {
        background: $white;

        .legend {
            color: $blue-1;
        }

        ul {
            li {
                a {
                    &:before {
                        background-color: $red;
                    }
                    svg path {
                        fill: $white;
                    }
                }
            }
        }
    }

    .section-form {
        background-color: $green;
        padding: 100px 0;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

    }

    @media screen and (max-width: $mobile) {
        .section-head {
            height: 100vh;
            padding-bottom: 18vh;

            .metas {
                font-size: 14px;
                line-height: 24px;
            }

            h1 {
                font-size: 26px;
                line-height: 36px;
            }

            .introduction {
                margin-top: 20px;
                font-size: 16px;
                line-height: 26px;
            }

            .button {
                margin-top: 20px;
            }
        }

        .section-book-head {
            max-height: 100%;
            padding: 100px 0 60px 0;

            .r-1 {
                flex-direction: column;

                .column-3 {
                    order: 1;
                    margin: 0;

                    img {
                        width: 120px;
                        height: auto;
                    }
                }

                .column-5 {
                    margin-top: 20px;
                    order: 2;
                }
            }

            .metas {
                font-size: 14px;
                line-height: 24px;
            }

            h1 {
                font-size: 26px;
                line-height: 36px;
            }

            .introduction {
                margin-top: 20px;
                font-size: 16px;
                line-height: 26px;
            }
        }

        .section-share {

            .legend {
                font-size: 16px;
                line-height: 16px;
            }

            ul {
                margin-left: auto;

                li {
                    width: 40px;
                    height: 40px;

                    &.twitter {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    &.linkedin {
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    &.mail {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }

}
.section-about {
    background-color: $blue-1;
    height: 100vh;

    .button {
        position: absolute;
        top: 50px;
        right: 50px;
        z-index: 22;
    }

    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .section-about__title {
        position: absolute;
        left: 50px;
        top: 50px;
        @include armin-grotesk-black(36px, 46px);
        color: white;
        text-transform: uppercase;

        span {
            color: $white;
        }
    }

    .step-1 {
        padding-top: 50px;
        @include center-xy;
        max-width: 450px;
        @include armin-grotesk-semi-bold(26px, 36px);
        color: white;
        z-index: 2;
    }

    .step-2  {
        padding-top: 50px;
        @include full-absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        .item {
            //opacity: 0;
            width: calc(100% / 3);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include armin-grotesk-black(28px, 38px);
            text-transform: uppercase;
            color: white;

            .nb {
                font-size: 18px;
                line-height: 18px;
                color: $white;
                margin-right: 20px;
                margin-top: -20px;
            }

            &.i-1 {
                justify-content: flex-start;
            }

            &.i-3 {
                justify-content: flex-end;
                padding-right: 50px;
            }
        }
    }

    .container-lottie {
        z-index: 1;
        padding: 160px 0 100px 0;
    }

    @media screen and (max-width: $mobile){
        display: none;
    }
}

.section-about-mobile {
    background-color: $blue-1;
    display: none;
    height: 500px;

    .grid {
        width: 100%;
    }

    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        padding: 0 90px 0 30px;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        overflow-x: scroll;

        .introduction {
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            height: 100%;

            .circle {
                width: 50px;
                height: 50px;
                background-color: white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 12px;
                    height: auto;
                }
            }

            h2 {
                margin-top: 20px;
                margin-bottom: 10px;
                @include armin-grotesk-black(25px, 30px);
                color: $green;
            }

            .text {
                @include armin-grotesk-semi-bold(16px, 24px);
                color: white;
                max-width: 210px;
            }

            .button {
                margin-top: 30px;

                .text {
                    font-size: 12px;
                }
            }
        }

        .list {
            margin-left: 60px;
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            padding-top: 30px;

            .legend {
                position: absolute;
                top: 30px;
                left: 0;
                @include armin-grotesk-black(25px, 30px);
                text-transform: uppercase;
                z-index: 10;
                color: white;

                .blue {
                    color: $white;
                }
            }

            .baseline {
                position: absolute;
                @include armin-grotesk-black(18px, 30px);
                color: $green;
                z-index: 100;
                bottom: 30px;
                left: 0;
            }

            .items {
                margin-left: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .about-item {
                    margin-left: 160px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;

                    &:first-child {
                        margin-left: 0;
                    }

                    .background {
                        @include center-xy;
                        width: 155px;
                        height: 270px;
                        margin-left: 40px;
                        z-index: 1;

                        svg {
                            width: 155px;
                            height: 270px;
                        }

                        .main {
                            width: 155px;
                            height: 270px;
                            position: relative;
                            z-index: 10;
                            transform: translate3d(0, 0, 0.1px);

                            svg {
                                width: 155px;
                                height: 270px;
                            }
                        }

                        .slave {
                            @include full-absolute;
                            will-change: transform;

                            svg {
                                @include center-xy;
                                width: 155px;
                                height: 270px;
                                fill: $white;
                            }

                            @for $i from 1 through 9
                            {
                                &.s-#{$i} {
                                    z-index: 10 - $i;
                                    transform: translate3d(calc(-14px * #{$i}), 0, 0.1px);
                                }
                            }
                        }
                    }

                    .about-item__content {
                        position: relative;
                        z-index: 2;
                    }

                    .nb {
                        position: absolute;
                        left: 0;
                        top: 4px;
                        @include armin-grotesk-black(13px, 13px);
                        color: $white;
                        transform: translate(-20px, 0);
                    }

                    .text {
                        @include armin-grotesk-black(20px, 25px);
                        color: white;
                    }

                    &.a-2 {
                        margin-left: 180px;

                        .background {
                            margin-left: -50px;

                            .slave {

                                @for $i from 1 through 9
                                {
                                    &.s-#{$i} {
                                        z-index: 10 - $i;
                                        transform: translate3d(calc(14px * #{$i}), 0, 0.1px);
                                    }
                                }
                            }
                        }
                    }

                    &.a-3 {
                        //margin-left: 360px;
                        margin-left: 130px;

                        .background {
                            margin-left: -30px;
                            width: 153px;
                            height: 272px;

                            .main,
                            .slave {
                                width: 153px;
                                height: 272px;

                                svg {
                                    width: 153px;
                                    height: 272px;
                                }
                            }

                            .slave {

                                @for $i from 1 through 9
                                {
                                    &.s-#{$i} {
                                        z-index: 10 - $i;
                                        transform: translate3d(calc(14px * #{$i}), 0, 0.1px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        display: block;
    }
}
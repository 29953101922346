#common {
    background-color: $blue-1;
    min-height: 50vh;
    padding: 190px 0 100px;

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    h1 {
        @include armin-grotesk-black(44px, 44px);
        color: $white;
        text-transform: uppercase;
    }

    @media screen and (max-width: $mobile) {
        padding-top: 120px;

        h1 {
            font-size: 30px;
            line-height: 1.1;
        }
    }

}
.block.block-head {
    height: 690px;
    overflow: hidden;

    .grid {
        padding-top: 170px;
        padding-bottom: 110px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        h1 {
            @include armin-grotesk-black(44px, 54px);
            color: white;
            text-transform: uppercase;

            span {
                display: block;

                &.line-1 {
                    @include set-grid-prop(margin-left, 0, 1);
                }

                &.line-2 {
                    @include set-grid-prop(margin-left, 1, 1);
                }
            }
        }

        .button {
            margin-top: 40px;
        }
    }

    .r-2 {
        margin-top: 40px;

        .column-4 {
            @include armin-grotesk-semi-bold(20px, 32px);
            @include set-grid-prop(margin-left, 1, 1);
            @include set-grid-prop(width, 10, 10);
        }
    }

    .r-3 {
        margin-top: 60px;

        .button-circle {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    @media screen and (max-width: $mobile) {
        height: 100vh;

        .grid {
            padding-top: 80px;
            padding-bottom: 0;
        }

        .r-1 {

            h1 {
                font-size: 25px;
                line-height: 30px;

                span {
                    margin-left: 0 !important;
                }
            }

            .button {
                margin-top: 20px;
            }
        }

        .r-2 {
            margin-top: 30px;

            .column-4 {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .r-3 {
            margin-top: 30px;

            .button-circle {
                margin-left: 0;
            }
        }
    }

}
.block.block-illustration-text {
    background-color: $blue-1;

    .grid {
        padding: 100px 0;
    }

    .r-1 {
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .block__content {
            position: relative;
            z-index: 2;

            h2 {
                @include armin-grotesk-black(36px, 46px);
                color: $white;

                span {
                    display: block;

                    &.line-1 {
                        @include set-grid-prop(margin-left, 0, 1);
                        @include set-grid-prop(width, 4, 5);
                    }

                    &.line-2 {
                        @include set-grid-prop(margin-left, 1, 1);
                        @include set-grid-prop(width, 4, 5);
                    }

                    &.line-3 {
                        @include set-grid-prop(margin-left, 0, 1);
                        @include set-grid-prop(width, 4, 5);
                    }
                }
            }

            .text-content {
                margin-top: 50px;
                @include set-grid-prop(margin-left, 1, 1);
                @include set-grid-prop(width, 4, 5);
            }
        }

        .block__illustration {
            position: absolute;
            width: 344px;
            height: 600px;
            @include set-grid-prop(right, 1, 1);
            top: 0;
            z-index: 1;

            svg {
                width: 344px;
                height: 600px;
            }

            .main {
                width: 344px;
                height: 600px;
                position: relative;
                z-index: 10;
            }

            .slave {
                @include full-absolute;
                will-change: transform;
                width: 344px;
                height: 600px;

                svg {
                    //@include center-xy;
                    fill: $white;
                }
            }
        }
    }

    &.type-1 {

        .r-1 {

            .block__illustration {

                &.shape-1 {
                    display: block;
                }

                &.shape-2 {
                    display: none;
                }

                .slave {
                    @for $i from 1 through 9
                    {
                        &.s-#{$i} {
                            z-index: 10 - $i;
                            transform: translate(calc(-30px * #{$i}), 0);
                        }
                    }
                }
            }
        }
    }

    &.type-2 {

        .r-1 {

            .block__content {
                h2 {

                    span {

                        &.line-1 {
                            @include set-grid-prop(margin-left, 5, 6);
                        }

                        &.line-2 {
                            @include set-grid-prop(margin-left, 6, 6);
                        }

                        &.line-3 {
                            @include set-grid-prop(margin-left, 5, 6);
                        }
                    }
                }

                .text-content {
                    @include set-grid-prop(margin-left, 6, 6);
                }
            }

            .block__illustration {
                @include set-grid-prop(left, 1, 1);

                &.shape-1 {
                    display: block;
                }

                &.shape-2 {
                    display: none;
                }

                .slave {
                    @for $i from 1 through 9
                    {
                        &.s-#{$i} {
                            z-index: 10 - $i;
                            transform: translate(calc(30px * #{$i}), 0);
                        }
                    }
                }
            }
        }
    }

    &.type-3 {

        .r-1 {

            .block__illustration {
                @include set-grid-prop(margin-right, 0, 1);

                &.shape-1 {
                    display: none;
                }

                &.shape-2 {
                    display: block;
                }

                .slave {
                    @for $i from 1 through 9
                    {
                        &.s-#{$i} {
                            z-index: 10 - $i;
                            transform: translate(calc(30px * #{$i}), 0);
                        }
                    }
                }
            }
        }
    }

    &.type-4 {
        .r-1 {
            .block__illustration {
                svg, .main {
                    width: 600px;
                    height: 600px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .grid {
            padding: 50px 0;
        }

        .r-1 {
            display: flex;
            align-items: center;
            flex-direction: column;

            .block__content {
                margin-top: 40px;
                order: 2;

                h2 {
                    font-size: 20px;
                    line-height: 24px;

                    span {
                        margin-left: 0 !important;
                        width: 100% !important;
                    }
                }

                .text-content {
                    margin-left: 0 !important;
                    width: 100%;
                }
            }

            .block__illustration {
                position: relative;
                left: auto !important;
                top: auto;
                right: auto;
                width: 144px;
                height: 250px;
                order: 1;

                svg {
                    width: 144px;
                    height: 250px;
                }

                .main,
                .slave {
                    width: 144px;
                    height: 250px;
                }
            }
        }

        &.type-1 {

            .r-1 {

                .block__illustration {
                    margin-left: 80px !important;

                    .slave {
                        @for $i from 1 through 9
                        {
                            &.s-#{$i} {
                                z-index: 10 - $i;
                                transform: translate(calc(-10px * #{$i}), 0);
                            }
                        }
                    }
                }
            }

        }

        &.type-2 {

            .r-1 {
                .block__illustration {
                    .slave {
                        @for $i from 1 through 9
                        {
                            &.s-#{$i} {
                                z-index: 10 - $i;
                                transform: translate(calc(10px * #{$i}), 0);
                            }
                        }
                    }
                }
            }

        }

        &.type-3 {

            .r-1 {
                .block__illustration {
                    .slave {
                        @for $i from 1 through 9
                        {
                            &.s-#{$i} {
                                z-index: 10 - $i;
                                transform: translate(calc(10px * #{$i}), 0);
                            }
                        }
                    }
                }
            }
        }
        &.type-4 {
            .r-1 {
                .block__illustration {
                    svg, .main {
                        width: 250px;
                        height: 250px;
                    }
                }
            }
        }
    }

}

.block.block-illustration-text.color-bkg-white {
    background-color: $white;

    .r-1 {
        .block__content {
            h2 {
                color: $blue-1;
            }

            .text-content {
                p, ul, li, li:before, a {
                    color: $blue-1;
                }
            }
        }
    }
}
.block.block-form {

    .r-0 {
        margin-bottom: 60px;

        h2 {

        }

        .subtitle {
            margin-top: 20px;
        }
    }

    padding: 100px 0;
    @include armin-grotesk-regular(18px, 30px);

    .column-8 {
        @include set-grid-prop(margin-left, 2, 2);
    }

    .gform_fields {
        display: flex !important;
        flex-wrap: wrap !important;
        grid-column-gap: 0 !important;
        grid-row-gap: 0 !important;
    }

    .gfield {
        width: calc(50% - 20px);
        margin-bottom: 40px;

        &:nth-child(even) {
            margin-left: 40px;
        }

        &.full {
            width: 100%;
            margin-left: 0;
        }

        textarea,
        input[type="email"],
        input[type="text"],
        input[type="tel"] {
            border: 1px solid $white;
            border-radius: 8px;
            height: 50px;
            width: 100%;
            padding: 0 10px;
            background-color: $blue-1;
            @include armin-grotesk-regular(18px, 30px);
            color: $green;
            transition: border-color 0.6s $easeOutQuart;
        }

        textarea {
            padding: 20px 10px;
            height: 240px;
            resize: none;
        }

        &.gfield_error {
            textarea,
            input[type="email"],
            input[type="text"],
            input[type="tel"] {
                border-color: $red;
            }
        }
    }

    .gfield_html {
        margin-left: 0 !important;
        @include armin-grotesk-semi-bold(14px, 24px);
        color: $green;
    }

    .gform_footer {
        justify-content: flex-start;
    }

    .gform_button {
        height: 50px;
        padding: 0 30px;
        @include armin-grotesk-regular(13px, 13px);
        text-transform: uppercase;
        color: $purple;
        padding-top: 5px;
        background-color: $white;
        border-radius: 30px;
    }

    .gform_validation_errors {
        display: none !important;
    }

    .validation_message {
        background-color: transparent;
        color: $red;
        @include armin-grotesk-regular(14px, 18px);
        border: none;
        padding-left: 0;
        padding-bottom: 0;
    }

    .gform_ajax_spinner {
        margin-left: 10px;
    }

    .gform_confirmation_wrapper  {
        @include armin-grotesk-semi-bold(20px, 28px);
        color: $white;
        text-align: center;
    }
    
    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-0 {
            margin-bottom: 40px;
        }

        .gfield {
            width: 100% !important;
            margin: 20px 0 0 0 !important;

            &:first-child {
                margin-top: 0 !important;
            }
        }
    }
}
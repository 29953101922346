.block.block-quote {
    background-color: $purple;
    padding: 150px 0;

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            @include set-grid-prop(right, 1, 1);
            transform: translate3d(0, -50%, 0.1px);

            svg {
                width: 374px;
                height: 252px;
            }
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(30px * #{$i}), calc(-50% + (20px * #{$i})), 0.1px);
                }
            }
        }
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        .column-6 {
            @include set-grid-prop(margin-left, 1, 1);
            color: white;

            .text {
                @include armin-grotesk-semi-bold(26px, 41px);
            }

            .author {
                margin-top: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .portrait {
                    flex: 0 0 auto;
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 40px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .metas {
                    flex: 0 0 auto;
                    @include armin-grotesk-regular(19px, 34px);
                    font-style: italic;
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        padding: 60px 0;

        .background {

            .main,
            .slave {
                position: absolute;
                top: 50%;
                @include set-grid-prop(right, 1, 1);
                transform: translate3d(0, -50%, 0.1px);

                svg {
                    width: 231px;
                    height: 156px;
                }
            }

            .slave {

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(14px * #{$i}), calc(-50% + (10px * #{$i})), 0.1px);
                    }
                }
            }

        }

        .r-1 {

            .column-6 {

                .text {
                    font-size: 16px;
                    line-height: 26px;
                }

                .author {

                    .portrait {
                        width: 50px;
                        height: 50px;
                        margin-left: 18px;
                    }

                    .metas {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
/**
BUTTON
 */
.button {
    cursor: pointer;
    display: inline-block;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 40px;
        background-color: black;
        width: 100%;
        height: 100%;
        transition: transform 0.6s $easeInOutQuart;
    }

    button,
    .button__item,
    a {
        height: 50px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    button {
        @include armin-grotesk-regular(13px, 13px);
        text-transform: uppercase;
        color: $blue-1;
        padding-top: 5px;
        margin: 0 auto;
    }

    span {
        @include armin-grotesk-regular(13px, 13px);
        text-transform: uppercase;
        color: $blue-1;
        padding-top: 5px;
    }

    html.desktop & {

        &:hover {
            &:before {
                transform: scaleX(1.1);
            }
        }
    }

    &.white {
        &:before {
            background-color: white;
        }
    }

    &.green {
        &:before {
            background-color: $green;
        }
    }

    &.cyan {
        &:before {
            background-color: $white;
        }
    }

    &.yellow {
        &:before {
            background-color: $yellow;
        }
    }

    &.blue {
        &:before {
            background-color: $blue-1;
        }

        span {
            color: white;
        }
    }
}

/**
BUTTON ICON
 */
.button-icon {

    a {
        background-color: white;
        width: 250px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 8px;
        padding: 0 20px;
        border: 1px solid white;
        transition: all 0.6s $easeOutQuart;

        .text {
            @include armin-grotesk-semi-bold(13px, 13px);
            color: $blue-1;
            padding-top: 4px;
            transition: color 0.6s $easeOutQuart;
        }

        svg {
            margin-left: auto;
            width: 15px;
            height: auto;
            fill: $red;
            transition: fill 0.6s $easeOutQuart, transform 0.6s $easeOutQuart;
        }
    }

    html.desktop & {

        &:hover {
            a {
                background-color: $blue-1;

                .text {
                    color: white;
                }

                svg {
                    transform: translate(4px, 0);
                    fill: white;
                }
            }
        }
    }
}

/**
BUTTON CIRCLE
 */
.button-circle {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 96px;
    height: 96px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: $red;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 1;
        transition: transform 0.6s $easeInOutQuart;
    }

    svg {
        z-index: 2;
        width: 18px;
        height: auto;
        transition: transform 0.6s $easeInOutQuart;
    }

    html.desktop & {

        &:hover {

            &:before {
                transform: scale(1.1);
            }

            svg {
                transform: translate(0, 4px);
            }
        }
    }

    &.white {

        &:before {
            background-color: white;
        }

        svg {
            width: 22px;
        }

        html.desktop & {

            &:hover {
                svg {
                    transform: translate(4px, 0);
                }
            }
        }
    }

    &.green {

        &:before {
            background-color: $green;
        }

        svg {
            width: 22px;
        }

        html.desktop & {

            &:hover {
                svg {
                    transform: translate(4px, 0);
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .button {
        button,
        .button__item,
        a {
            height: 40px;
            padding: 0 25px;
        }
    }
    .button-circle {
        width: 50px;
        height: 50px;

        svg {
            width: 12px;
        }
    }
}
.section-references {
    background-color: $purple;
    padding: 100px 0;

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-1 {

        h2 {
            color: $yellow;
            text-align: center;
            /*@include armin-grotesk-black(36px, 36px);*/
        }
    }

    .r-2 {
        margin-top: 50px;

        .column-8 {
            @include set-grid-prop(margin-left, 2, 2);
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -30px;

            li {
                margin-bottom: 20px;
                margin-left: 20px;

                .li__wrapper,
                a {
                    height: 40px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    border: 1px solid rgba(255, 255, 255, 0.4);
                    color: white;

                    .text {
                        padding-top: 2px;
                        @include armin-grotesk-black(14px, 14px);
                    }
                }

                a {
                    transition: all 0.6s $easeOutQuart;

                    html.desktop & {
                        &:hover {
                            background-color: white;
                            color: $purple;
                        }
                    }
                }
            }
        }
    }

    .r-3 {
        $marge: get-grid-percent(1, 0);
        $width: get-grid-percent(1, 2);
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        .overflow-hidden__container {
            padding-top: 100px;
        }

        .reference-item {
            margin-bottom: $marge;
            position: relative;
            width: $width;
            @include set-grid-prop(margin-left, 1, 0);

            &:nth-child(5n + 1) {
                @include set-grid-prop(margin-left, 1, 1);
            }

            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            .reference-item__inner {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
            }

            .circles {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                pointer-events: none;

                div {
                    left: 0;
                    top: 0;
                    position: absolute;
                    border: 1px solid $white;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: $purple;
                }

                .main {
                    border-color: $white;
                    z-index: 10;
                    transition: background-color 0.6s $easeOutQuart;
                    transform: translate3d(0, 0, 0.1px);
                }

                .slave {
                    opacity: 0;
                    transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                }

                @for $i from 1 through 4
                {
                    .s-#{$i} {
                        z-index: 10 - $i;
                        //transform: translate3d(calc(14px * #{$i}), calc(10px * #{$i}), 0.1px);
                    }
                }
            }

            .logo {
                position: relative;
                height: 100%;
                width: 100%;
                z-index: 2;
                transform: translate3d(0, 0, 0.1px);

                div {
                    @include full-absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: opacity 0.6s $easeOutQuart;
                    z-index: 1;

                    img {
                        max-width: 60%;
                        height: auto;
                    }

                    &.on {
                        opacity: 0;
                        z-index: 2;
                    }
                }
            }

            html.desktop & {

                &:hover {

                    .circles {

                        .main {
                            background-color: white;
                        }

                        @for $i from 1 through 4
                        {
                            .s-#{$i} {
                                opacity: 1;
                                transform: translate(calc(14px * #{$i}), calc(10px * #{$i}));
                            }
                        }
                    }

                    .logo {

                        .off {
                            opacity: 0;
                        }

                        .on {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .column-10 {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .button-container {
            margin-top: 40px;
            text-align: center;
        }

    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-1 {
            h4 {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .grid {
            width: 100%;
        }

        .r-2 {
            display: none;
        }

        .r-3 {
            margin-top: 30px;

            .overflow-hidden__container {
                padding-top: 0;
            }

            .reference-item {
                margin-left: 0 !important;
                margin-bottom: 0 !important;
                width: calc(100% / 3);
            }
        }
    }
}
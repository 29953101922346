.section-new {
    padding: 80px 0;
    background-color: $white;

    .grid,
    .row {
        height: 100%;
    }

    .grid {
        width: calc(100% - 100px);
    }

    .r-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        [class^="column"] {
            float: none;
            flex: 0 0 auto;
        }
    }

    .socials {
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .legend {
            flex: 0 0 auto;
            @include armin-grotesk-black(20px, 20px);
            color: $blue-1;
            text-transform: uppercase;
            padding-top: 6px;
        }

        ul {
            margin-left: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                width: 50px;
                height: 50px;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: $red;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: 1;
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    svg {
                        z-index: 2;
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }
                }

                &.instagram {
                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }

                &.linkedin {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.youtube {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

    .r-1 {

        h2 {
            //@include armin-grotesk-black(36px, 46px);
            color: $blue-1;
            text-transform: uppercase;

            .l-2 {
                margin-left: 40px;
            }
        }

        .button {
            margin-left: auto;
        }
    }

    .r-2 {
        margin-top: 110px;

        .c-2 {
            @include set-grid-prop(margin-left, 1, 0);
            padding-left: 30px;
        }
    }

    .new-item {
        width: 100%;

        html.desktop & {

            &:hover {
                span {
                    opacity: 1;
                }

                .s-2 {
                    transform: translate(calc(-50% - 16px), calc(-50% - 16px));
                }

                .s-3 {
                    transform: translate(calc(-50% - 32px), calc(-50% - 32px));
                }
            }
        }

        a {
            display: block;
            width: 100%;
        }

        .thumbnail {
            width: 100%;
            height: 258px;
            flex: 0 0 auto;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                z-index: 10;
            }
        }

        .metas {
            position: relative;
            padding: 30px 20px;
            @include armin-grotesk-semi-bold(26px, 42px);
            color: $blue-1;
            z-index: 10;
        }

        span {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            display: block;
            width: calc(100% + 60px);
            height: calc(100% + 60px);
            border: 1px solid $blue-1;
            //background-color: $white;
            left: 54%;
            top: 54%;
            transform: translate(-50%, -50%);
            transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;

            &.s-1 {
                z-index: 3;
            }

            &.s-2 {
                z-index: 2;
            }

            &.s-3 {
                z-index: 1;
            }
        }
    }

    .new-item-hz {
        position: relative;

        &:last-child {
            margin-top: 40px;
        }

        html.desktop & {

            &:hover {
                span {
                    opacity: 1;
                }

                .s-2 {
                    transform: translate(calc(-50% - 16px), calc(-50% - 16px));
                }

                .s-3 {
                    transform: translate(calc(-50% - 32px), calc(-50% - 32px));
                }
            }
        }

        a {
            display: flex;
            align-items: center;
        }

        .thumbnail {
            z-index: 10;
            position: relative;
            width: 384px;
            height: 226px;
            flex: 0 0 auto;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .metas {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 30px;
            color: $blue-1;
            @include armin-grotesk-semi-bold(26px, 42px);
        }

        span {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            display: block;
            width: calc(100% + 60px);
            height: calc(100% + 60px);
            border: 1px solid $blue-1;
            //background-color: $white;
            left: 52%;
            top: 52%;
            transform: translate(-50%, -50%);
            transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;

            &.s-1 {
                z-index: 3;
            }

            &.s-2 {
                z-index: 2;
            }

            &.s-3 {
                z-index: 1;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        background-color: $white;
        padding: 60px 0;

        .grid {
            width: 100%;
        }

        .r-1 {
            margin: 0 auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 30px;

            .button {
                margin-left: 0;
                margin-top: 20px;
            }

            .column-8 {
                h2 {
                    .l-2 {
                        margin-left: 0;
                    }
                }
            }
        }

        .socials {
            position: relative;
            right: auto;
            top: auto;
            order: 3;
            padding: 0 30px;

            .legend {
                font-size: 16px;
                line-height: 1;
            }

            ul {
                margin-left: auto;

                li {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .r-2 {
            padding-left: 30px;
            position: relative;
            margin-top: 60px;

            .row__inner {
                display: inline-flex;
                overflow-x: scroll;
                overflow-y: hidden;
                width: 100%;
            }

            [class^='column'] {
                width: auto !important;
                flex: 0 0 auto;
                padding-left: 0;
            }

            .new-item,
            .new-item-hz {
                width: calc(90vw - 60px);
                flex: 0 0 auto;
                margin-top: 0;
                margin-left: 30px;

                a {
                    flex-direction: column;
                }

                .thumbnail {
                    width: 100%;
                    height: 185px;
                }

                .metas {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 20px 0;
                }
            }

            .new-item {
                margin-left: 0;
            }

            .c-2 {
                display: inline-flex;
            }
        }

        .r-3 {
            margin-top: 60px;
        }
    }
}
.block.block-stories {
    background-color: $white;
    padding: 100px 0 50px 0;

    .r-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .button {
            margin-left: auto;
            @include set-grid-prop(margin-right, 1, 1);
        }

        [class^="column"] {
            float: none;
        }

        .column-8 {
            @include set-grid-prop(margin-left, 0, 1);

            span {
                display: block;
            }

            .l-2 {
                margin-left: 4.641350211%;
            }
        }

        h2 {
            color: $blue-1;
        }
    }

    .r-2 {
        margin-top: 50px;

        .column-10 {
            $marge: get-grid-percent(2, 0);
            $bottom: get-grid-percent(1.5, 0);
            @include set-grid-prop(margin-left, 1, 1);
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;

            .storie-item {
                width: calc((100% - #{$marge}) / 3);
                margin-bottom: $bottom;

                .thumbnail span {
                    border: 2px solid $blue-1;
                    background-color: $white;
                }

                .title a, 
                .excerpt a, 
                .tags ul li .text {
                    color: $blue-1;
                }

                .tags ul li {
                    border-color: $blue-1; 
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        padding: 60px 0 0 0;

        .grid {
            width: 100%;
        }

        .r-1 {
            width: calc(100% - 60px);
            margin: 0 auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .button {
                margin-left: 0;
                margin-top: 20px;
            }

            .column-8 {
                h2 {

                    .l-2 {
                        margin-left: 0;
                    }
                }
            }
        }

        .r-2 {
            overflow-x: scroll;
            padding: 0 0 30px 0;

            .column-10 {
                display: inline-flex;
                width: auto !important;
                flex-wrap: nowrap;
                padding: 0 30px;

                .storie-item {
                    width: 270px;
                    margin-left: 30px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
.block.block-performance {
    padding: 200px 0;
    background-color: $blue-1;

    .background {
        @include center-xy;
        width: 1000px;
        height: 445px;
        z-index: 1;

        .mobile {
            @include center-xy;
            display: none;
            width: 300px;
            height: auto;
        }

        .line-top {
            position: absolute;
            top: 0;
            left: 50%;
            width: 780px;
            height: 1px;
            transform: translate(-50%, 0);
            overflow: hidden;

            svg {
                width: 780px;
                height: 1px;
            }
        }

        .line-bottom {
            position: absolute;
            bottom: 4px;
            left: 50%;
            width: 780px;
            height: 1px;
            transform: translate(-50%, 0);
            overflow: hidden;

            svg {
                width: 780px;
                height: 1px;
            }
        }

        .left {
            position: absolute;
            width: 352px;
            height: auto;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .right {
            position: absolute;
            top: 0;
            right: 0;
            width: 331px;
            height: auto;
        }
    }

    .r-1 {
        @include armin-grotesk-black(44px, 54px);
        color: white;
        text-transform: uppercase;

        .c-1 {
            @include set-grid-prop(margin-left, 0, 1);
        }

        .c-2 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .c-3 {
            @include set-grid-prop(margin-left, 0, 1);
        }
    }

    .r-2 {
        margin-top: 200px;

        .column-3 {
            @include set-grid-prop(width, 3, 3);
            z-index: 2;
        }

        .c-1 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .c-2 {
            float: right;
            @include set-grid-prop(margin-right, 1, 1);
            text-align: right;
        }

        .center {
            @include center-xy;
            @include armin-grotesk-black(36px, 46px);
            color: $green;
            text-transform: uppercase;
            z-index: 2;
        }

        .title {
            @include armin-grotesk-black(36px, 46px);
            color: $green;
            text-transform: uppercase;
        }

        ul {
            margin-top: 60px;

            li {
                margin-top: 40px;
                @include armin-grotesk-black(28px, 38px);
                text-transform: uppercase;
                color: white;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 130px 0 60px 0;

        .r-1 {
            @include armin-grotesk-black(24px, 30px);

            div {
                display: inline;
                width: auto !important;
            }
        }

        .r-2 {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 50px;

            .background {
                .mobile {
                    display: block;
                }
            }

            .line-top,
            .line-bottom {
                display: none;
            }

            .left,
            .right {
                display: none;
            }

            .center {
                display: none;
            }

            .c-1 {
                width: 50% !important;
                padding-right: 10px;
            }

            .c-2 {
                width: 50% !important;
                margin-right: 0;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .title {
                    text-align: right;
                }
            }

            .title {
                font-size: 24px;
                line-height: 26px;
            }

            ul {
                margin-top: 20px;

                li {
                    font-size: 18px;
                    line-height: 22px;
                    margin-top: 14px;
                }
            }
        }
    }
}
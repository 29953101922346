.block.block-image {
    padding-top: 100px;
    padding-bottom: 100px;

    &.full-1 {
        padding-top: 0;
        padding-bottom: 0;

        .grid {
            width: 100%;
            max-width: 100%;
        }

        .column-10 {
            width: 100%;
            margin-left: 0;
        }
    }

    &.free-1 {

        img {
            position: relative;
            display: block;
            margin: 0 auto;
        }
    }

    .video-container {
        cursor: none !important;
    }

    .icon {
        display: none;
        @include center-xy;
        width: 46px;
        height: 46px;
        align-items: center;
        justify-content: center;
        z-index: 3;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $red;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            z-index: 1;
            transition: transform 0.6s $easeInOutQuart;
        }

        svg {
            width: 18px;
            height: auto;
            fill: white;
            z-index: 2;
        }
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    @media screen and (max-width: $mobile) {
        padding-top: 50px;
        padding-bottom: 50px;

        .video-container {
            .icon {
                display: flex;
            }
        }
    }
}

.block-text + .block-image {
    padding-top: 0;
}

.block-image + .block-image {
    padding-top: 0;
}


/**
DRAW
 */
[data-animation-type="draw"] {
    opacity: 0;
    transform: translate(0, 80px);
}

/**
FADE
 */
[data-animation-type="fade"] {
    @include autoAlpha(0);
    transform: translate3d(0, 50px, 0);

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0);
            @include autoAlpha(1);
        }
    }
}

[data-animation-type="fade-down"] {
    @include autoAlpha(0);
    transform: translate3d(0, -50px, 0);

    html.mobile & {
        transform: translate3d(0, 50px, 0);
    }

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0);
            @include autoAlpha(1);
        }
    }
}

/**
DUPLICATE RIGHT
 */
[data-animation-type="duplicate-right"] {

    .main,
    .slave {
        svg {
            @include autoAlpha(0);
            transform: translate(-20px, 0);
        }
    }

    html.has-dom-ready & {

        &.is-inview {

            .main,
            .slave {

                svg {
                    transition: all 1.2s $easeOutQuart;
                    transform: translate(0, 0);
                    @include autoAlpha(1);
                }

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        svg {
                            transition-delay: calc(0.05s * #{$i});
                        }
                    }
                }
            }
        }
    }
}


/**
DUPLICATE LEFT
 */
[data-animation-type="duplicate-left"] {

    .main,
    .slave {
        svg {
            @include autoAlpha(0);
            transform: translate(20px, 0);
        }
    }

    html.has-dom-ready & {

        &.is-inview {

            .main,
            .slave {

                svg {
                    transition: all 1.2s $easeOutQuart;
                    transform: translate(0, 0);
                    @include autoAlpha(1);
                }

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        svg {
                            transition-delay: calc(0.05s * #{$i});
                        }
                    }
                }
            }
        }
    }
}

/**
TRANSLATE RIGHT
 */
[data-animation-type="translate-right"] {
    @include autoAlpha(0);
    transform: translate3d(-50px, 0, 0.1px);

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0.1px);
            @include autoAlpha(1);
        }
    }
}

/**
TRANSLATE LEFT
 */
[data-animation-type="translate-left"] {
    @include autoAlpha(0);
    transform: translate3d(50px, 0, 0.1px);

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0.1px);
            @include autoAlpha(1);
        }
    }
}

/**
SPLIT CHARS
 */
[data-animation-type="chars"] {

    .line {
        overflow: hidden;
        padding-bottom: 0.14em;

        &.line1 {
            margin-top: 0;
        }
    }

    .char {
        opacity: 0;
        transform: translate3d(0, 100%, 0);

        @for $i from 1 through 100 {
            &:nth-child(#{$i}n) {
                transition-delay: calc(0.025s * #{$i}) !important;
            }
        }
    }

    html.has-dom-ready & {

        .char {
            transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
        }

        &.is-inview {

            .char {
                transform: translate3d(0, 0, 0) rotate(0.01deg);
                @include autoAlpha(1);
            }
        }
    }
}

/**
SPLIT WORDS
 */
[data-animation-type="words"] {

    .word {
        position: relative;
        overflow: hidden;

        .overflow-wrapper {
            position: relative;
            opacity: 0;
            transform: translate3d(0, 90%, 0);
        }

        @for $i from 1 through 1000 {
            &:nth-child(#{$i}n) {
                .overflow-wrapper {
                    transition-delay: calc(0.025s * #{$i}) !important;
                }
            }
        }
    }

    html.has-dom-ready & {

        .overflow-wrapper {
            transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
        }

        &.is-inview {
            .overflow-wrapper {
                @include autoAlpha(1);
                transform: translate3d(0, 0, 0) rotate(0.01deg);;
            }
        }
    }
}

/**
SLIDE RIGHT
 */
[data-animation-type="slide-right"] {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);

    html.has-dom-ready & {
        transition: transform 2s $easeOutQuart, opacity 2s $easeOutQuart;

        &.is-inview {
            @include autoAlpha(1);
            transform: translate3d(0, 0, 0);
        }
    }
}

/**
SCALE X
 */
[data-animation-type="scalex"] {
    transform-origin: 0 0;
    transform: scaleX(0);

    html.has-dom-ready & {
        transition: transform 1.4s $easeInOutQuart;

        &.is-inview {
            transform: scaleX(1);
        }
    }
}

/**
SCALE OUT
 */
[data-animation-type="scale-out"] {
    transform: scale(1.1);

    html.has-dom-ready & {
        transition: transform 2s $easeOutQuart;

        &.is-inview {
            transform: scaleX(1);
        }
    }
}

/**
CAROUSEL
 */
[data-carousel="item"] {
    @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
            transition-delay: calc(0.1s * #{$i}) !important;
        }
    }
}

/**
SCALE DOWN
 */
[data-animation-type="scale-down"] {
    opacity: 0;
    transform: scale(1.2);

    html.has-dom-ready & {
        transition: transform 1.4s $easeOutQuart, opacity 1s $easeOutQuart;

        &.is-inview {
            opacity: 1;
            transform: scale(1);
        }
    }
}

/**
SCALE DOWN NO ALPHA
 */
[data-animation-type="scale-down-no-alpha"] {
    transform: scale(1.2);

    html.has-dom-ready & {
        transition: transform 1.4s $easeInOutQuart;

        &.is-inview {
            transform: scale(1);
        }
    }
}
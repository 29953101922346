html {
    //background-color: white;
}

body {
    position: relative;
    background-color: $blue-1;
    color: white;
    font-size: 100%;
    min-height: 100%;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.main__wrapper {
    //will-change: transform;
}

.wrapper {
    opacity: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.basicLightbox {
    z-index: 10000000 !important;
}

.block {
    position: relative;
}

.overflow-hidden__container {
    overflow: hidden;
    width: 100%;

    .overflow-hidden__wrapper {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

/**
SVG
 */
img,
svg {
    display: block;
}

/**
TRANSITION
 */
#transition {
    @include full-fixed;
    z-index: 10000000000000;

    .panel {
        @include full-absolute;
        width: 100vw;
        height: 100vh;

        &.p-1 {
            background-color: $white;
            z-index: 10;
        }

        &.p-2 {
            background-color: $yellow;
            z-index: 9;
        }

        &.p-3 {
            background-color: $green;
            z-index: 8;
        }

        &.p-4 {
            background-color: $blue-1;
            z-index: 7;
        }
    }
}

#page {
    background-color: $blue-1;
}

/**
CURSOR
 */
.cursor {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.6s $easeOutQuart;

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: $yellow;
        transform: scale(0.8);
        z-index: 1;
        transition: transform 0.6s $easeOutQuart;
    }

    &.play {

        svg {
            z-index: 2;
            width: 25px;
            height: 20px;
            fill: $blue-1;
        }

        .stop-icon {
            display: none;
            fill: $blue-1;
        }

        &.is-playing {

            .play-icon {
                display: none;
            }

            .stop-icon {
                display: block;
            }
        }
    }

    &.drag {

        svg {
            z-index: 2;
            width: 56px;
            height: auto;
            fill: $blue-1;
        }
    }

    &.link {

        .circle {
            background-color: $blue-1;
        }

        svg {
            z-index: 2;
            width: 20px;
            height: auto;
            fill: white;
        }
    }
}

/**
OVERLAY
 */
.overlay {
    @include autoAlpha(0);
    @include full-fixed;
    background-color: rgba(46, 20, 82, 0.6);
    z-index: 5000000;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay__wrapper {
        background-color: $blue-1;
        padding: 70px;
        max-width: 80%;
        width: 940px;
    }

    .overlay__close {
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px;

        svg {
            width: 26px;
            height: auto;
            transition: transform 0.6s $easeInOutQuart;
        }

        html.desktop & {
            &:hover {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .overlay__title {
        @include armin-grotesk-black(36px, 46px);
        text-transform: uppercase;
        color: $white;

        .l-2 {
            margin-left: 50px;
        }
    }

    .overlay__subtitle {
        margin-top: 30px;
        margin-left: 50px;
        @include armin-grotesk-semi-bold(26px, 36px);
        color: white;
    }

    .overlay__content {
        padding-left: 50px;
        margin-top: 40px;
        max-width: 690px;
    }

    .overlay__legals {
        margin-top: 40px;
        max-width: 690px;
        padding-left: 50px;
        @include armin-grotesk-regular(11px, 16px);
        color: $white;
    }
}

/**
BOOK
 */
.lateral {
    @include autoAlpha(0);
    @include full-fixed;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50000010;

    .close {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;

        svg {
            width: 16px;
            height: 16px;
            transition: transform 0.6s $easeInOutQuart;

            path {
                fill: white !important;
            }
        }

        html.desktop & {
            &:hover {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .lateral__container {
        padding: 100px 20px 60px 20px;
        position: relative;
        background-color: $green;
        width: calc(100vw / 3);
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .gform_fields {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 0;
        grid-row-gap: 0;

        .gfield_description.validation_message {
            background-color: transparent;
            color: $red;
            @include armin-grotesk-regular(14px, 18px);
            border: none;
            padding-left: 0;
            padding-bottom: 0;
        }
    }

    .gfield {
        margin-bottom: 10px;

        textarea,
        input[type="email"],
        input[type="text"],
        input[type="tel"] {
            border: 1px solid $blue-1;
            border-radius: 8px;
            height: 50px;
            width: 100%;
            padding: 0 10px;
            background-color: $green;
            @include armin-grotesk-regular(18px, 30px);
            color: $blue-1;
            transition: border-color 0.6s $easeOutQuart;
        }

        textarea {
            padding: 20px 10px;
            height: 240px;
            resize: none;
        }

        &.gfield_error {
            textarea,
            input[type="email"],
            input[type="text"],
            input[type="tel"] {
                border-color: $red;
            }
        }
    }

    .gfield_html {
        margin-left: 0 !important;
        @include armin-grotesk-semi-bold(14px, 24px);
        color: $blue-1;
    }

    .gform_footer {
        justify-content: flex-start;
    }

    .gform_button {
        height: 50px;
        padding: 0 30px;
        @include armin-grotesk-regular(13px, 13px);
        text-transform: uppercase;
        color: $green;
        padding-top: 5px;
        background-color: $blue-1;
        border-radius: 30px;
    }

    .gform_wrapper .gform_validation_errors {
        display: none !important;
    }

    .gform_ajax_spinner {
        margin-left: 10px;
    }

    .gform_confirmation_wrapper  {
        @include armin-grotesk-semi-bold(20px, 28px);
        color: white;
        text-align: center;
    }
}


#flip {
    display: none;
    @include full-fixed;
    background-color: $blue-1;
    z-index: 10000000000000000;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include armin-grotesk-semi-bold(20px, 30px);
    color: white;
    padding: 30px;

    @media screen and (max-width: $mobile) and (orientation:landscape) {
        display: flex;
    }
}

#flip-tablet {
    display: none;
    @include full-fixed;
    background-color: $blue-1;
    z-index: 10000000000000000;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include armin-grotesk-semi-bold(20px, 30px);
    color: white;
    padding: 10vw;

    html.tablet & {
        @media screen and (orientation:portrait) {
            display: flex;
        }
    }
}

@media screen and (max-width: $mobile) {

    .cursor {
        display: none !important;
    }

    .overlay {

        .overlay__wrapper {
            max-width: 90%;
            height: 80vh;
            overflow-y: scroll;
            padding: 60px 40px;

            .overlay__close {
                right: 20px;

                svg {
                    width: 24px;
                    height: 18px;
                }
            }

            .overlay__title {
                font-size: 25px;
                line-height: 30px;
            }

            .overlay__subtitle {
                margin-left: 0;
                padding-left: 0;
                font-size: 16px;
                line-height: 24px;
                margin-top: 20px;
            }

            .overlay__content {
                padding-left: 0;
                margin-top: 20px;

                .bforms {
                    width: 100%;
                }

                form {
                    flex-direction: column;

                    .gform-body {
                        width: 100%;
                    }

                    .gform_footer {
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }

                input {
                    height: 40px;
                    font-size: 13px;
                }

                .button {
                    margin-top: 20px;
                }

                .form__wrapper {
                    flex-direction: column;
                }

                #error {
                    font-size: 13px;
                }
            }

            .overlay__legals {
                padding-left: 0;
            }
        }
    }

    .lateral {
        .lateral__container {
            width: 100%;
        }
    }
}
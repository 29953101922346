#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    z-index: 1000;

    .logo {
        position: absolute;
        top: 30px;
        left: -20px;
        background-color: rgba(0, 0, 0, 0.3);
        height: 80px;
        padding: 0 30px 0 50px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: transform 0.6s $easeInOutQuart;

        svg {
            width: 188px;
            height: auto;

            &.mobile {
                display: none;
                width: 126px;
                height: auto;
            }
        }

        html.desktop & {
            &:hover {
                transform: translate(20px, 0);
            }
        }
    }

    .menu {
        position: absolute;
        right: 0;
        top: 30px;
        height: 80px;
        z-index: 100;

        .contact-button {
            position: absolute;
            right: -12px;
            top: 0;
            padding: 0 30px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.3);
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: right 0.6s $easeInOutQuart;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                width: 36px;
                height: 36px;
            }

            .text {
                padding-top: 4px;
                margin-left: 20px;
                text-transform: uppercase;
                @include armin-grotesk-regular(13px, 13px);
                color: white;
            }

            html.desktop & {
                &:hover {
                    right: 80px;
                }
            }
        }

        .open-button {
            cursor: pointer;
            width: 80px;
            height: 80px;
            background-color: $red;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .line {
                width: 32px;
                height: 2px;
                background-color: white;
                margin-top: 6px;
                transition: transform 0.6s $easeInOutQuart;

                &.l-2 {
                    transition-delay: 0.05s;
                }

                &.l-3 {
                    transition-delay: 0.1s;
                }
            }

            html.desktop & {
                &:hover {
                    .line {
                        transform: scaleX(1.3);
                    }
                }
            }
        }

        &.disabled {

            .contact-button {
                right: -92px;

                html.desktop & {
                    &:hover {
                        right: 0;
                    }
                }
            }

            .open-button {
                display: none;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        height: 80px;
        z-index: 200;
        width: 100%;

        .logo {
            top: 50%;
            left: 20px;
            padding: 0;
            background-color: transparent;
            border-radius: 0;
            transform: translate(0, -50%);

            svg {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        .menu {
            top: 0;

            .contact-button {
                background-color: transparent;
                border-radius: 0;
                border-left: 1px solid rgba(255, 255, 255, 0.2);
                right: -2px;
                //padding-left: 20px;

                svg {
                    width: 26px;
                    height: 26px;
                }

                .text {
                    opacity: 0;
                }
            }
        }
    }

}
.block.block-text {
    padding: 100px 0;

    &:first-child {
        padding-top: 180px;
    }

    .column-8 {
        @include set-grid-prop(margin-left, 1, 2);
    }

    .text-content {

        h2 {
            transform: translate(-50px, 0);
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        figure {
            margin: 1rem;
            position: relative;
            width: calc(100% - 2rem) !important;

            figcaption {
                font-size: 0.9rem;
                text-align: center;
                background: rgba(250,250,250, .8);
                bottom: 0;
                box-sizing: border-box;
                position: absolute;
                width: 100%;
                padding: 1rem;
                -webkit-transition: max-height 0.3s ease-out;
                overflow: hidden;
            }
        }


    }

    @media screen and (min-width: calc($mobile + 1px)) {
        .text-content {
            figure {
                /*width: calc(50% - 2rem);*/

                figcaption {
                    height: 50px;
                }

                &:hover {
                    figcaption {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        transition: height .3s ease-in;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .text-content {
            h2 {
                transform: translate(0, 0);
            }

            figure {
                width: 100%;

                figcaption {
                    height: 0.8rem;
                    max-height: 50px;
                    padding-bottom: 0.2rem;
                }
            }
        }
    }
}
.block.block-text.color-bkg-blue {
    background-color: $blue-1;

    .text-content {
        h1, h2, h3, h4, p, ul, ol, li, li:before, a {
            color: $white;
        }

        figcaption {
            color: $blue-1;
        }
    }

}
.block.block-text.color-bkg-white {
    background-color: $white;

    .text-content {
        h1, h2, h3, h4, p, ul, ol, li, li:before, a, figcaption {
            color: $blue-1;
        }
        ul {
            li {
                &:before {
                    background-color: $blue-1;
                }
            }
        }
    }
}
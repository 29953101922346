.section-hero {
    position: relative;
    //background-color: $blue-1;
    height: 100vh;
    width: 100%;
    z-index: 1;

    .background {
        background: $blue-1;
        @include full-fixed;
        pointer-events: none;
        z-index: 1;
    }

    .section__inner {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .grid,
    .row,
    .column-12 {
        height: 100%;
        z-index: 3;
        transform: translate3d(0, 0, 0.1px);
    }

    .ghost {
        position: absolute;
        height: 100%;
        width: 50vw;
        z-index: 50;
        top: 0;
        left: 0;

        &.right {
            left: 50vw;
        }
    }

    h1 {
        @include full-absolute;
        @include armin-grotesk-black(44px, 54px);
        color: white;
        text-transform: uppercase;
        padding-top: 45vh;
        text-align: right;
        z-index: 10;

        span {
            display: block;

            &.l-1 {
                @include set-grid-prop(margin-right, 1, 2);
            }

            &.l-2 {
                @include set-grid-prop(margin-right, 1, 1);
            }

            &.l-3 {
                @include set-grid-prop(margin-right, 1, 2);
            }
        }
    }

    .duplicate-elements {
        pointer-events: none;
        @include full-absolute;
        //transform: translate(0, -5%);
        z-index: 1;

        .main {
            @include center-xy;
            width: 514px;
            height: 464px;
            position: relative;
            z-index: 10;
            transform: translate3d(-50%, -50%, 0.1px);

            svg {
                width: 514px;
                height: 464px;
                fill: $white;
            }
        }

        .slave {
            @include full-absolute;
            will-change: transform;
            transition: transform 0.2s $easeOutQuart;

            svg {
                @include center-xy;
                width: 514px;
                height: 464px;
                stroke: $white;
                fill: $blue-1;
            }

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(-30px * #{$i}), calc(18px * #{$i}), 0.1px);
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: 500px;
        background-color: $blue-1;

        .background {
            display: none;
        }

        .duplicate-elements {

            .main {
                padding-top: 50px;
                width: 200px;
                height: 180px;

                svg {
                    width: 200px;
                    height: 180px;
                }
            }

            .slave {
                top: 50px;

                svg {
                    width: 200px;
                    height: 180px;
                }

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(8px * #{$i}), calc(-4px * #{$i}), 0.1px);
                    }
                }
            }
        }

        h1 {
            padding-top: 270px;
            font-size: 25px;
            line-height: 30px;

            span {
                display: block;

                &.l-1 {
                    margin-right: 60px;
                }

                &.l-2 {
                    margin-right: 0;
                }

                &.l-3 {
                    margin-right: 20px;
                }
            }
        }
    }
}
.block-text-2-c {
    background-color: $blue-1;
    padding: 100px 0;
    overflow: hidden;

    &.type-1 {
        .b-1 {
            display: block;
        }
    }

    &.type-2 {
        .b-2 {
            display: block;
        }
    }

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;
        display: none;

        &.b-1 {

            .slave {

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(-50px * #{$i}), -50%, 0.1px);
                    }
                }
            }
        }

        &.b-2 {

            .main,
            .slave {
                @include set-grid-prop(left, 4.5, 5);
                top: 40%;

                svg {
                    width: 460px;
                    height: 820px;
                }
            }

            .slave {

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(50px * #{$i}), -50%, 0.1px);
                    }
                }
            }
        }

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            right: -90px;
            transform: translate3d(0, -50%, 0.1px);

            svg {
                width: 664px;
                height: 1155px;
            }
        }
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        h2 {
            color: $white;
            @include armin-grotesk-black(36px, 46px);

            span {
                display: block;
                @include set-grid-prop(margin-left, 0, 1);

                &.l-2 {
                    @include set-grid-prop(margin-left, 1, 1);
                }
            }
        }
    }

    .r-2 {

        .column-4 {
            @include set-grid-prop(margin-left, 1, 1);
            margin-top: 30px;

            .text-content {
                @include armin-grotesk-semi-bold(19px, 31px);
            }
        }
    }

    .r-3 {
        margin-top: 50px;

        .column-9 {
            @include set-grid-prop(margin-left, 1, 1);
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;

            .text-item {
                flex: 0 0 auto;
                margin-top: 50px;
                width: 40%;
                margin-left: 20%;
                @include armin-grotesk-semi-bold(19px, 31px);

                h3 {
                    color: $white;
                }

                &:nth-child(2n + 1) {
                    margin-left: 0;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }

                .number {
                    transform: translate(-30px, 0);
                    font-size: 18px;
                    line-height: 1;
                }
            }
        }

        .text-item {
            position: relative;
            margin-top: 40px;

            .number {
                position: absolute;
                left: 0;
                top: 4px;
                transform: translate(-30px, 0);
                @include armin-grotesk-black(18px, 18px);
                color: $white;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .background {
            opacity: 0.6;

            .main,
            .slave {
                width: 480px;
                height: 853px;

                svg {
                    width: 480px;
                    height: 853px;
                }
            }

            &.b-1 {
                .main,
                .slave {
                    left: 50%;
                }
            }

            &.b-2 {
                .main,
                .slave {
                    left: -100px;
                }
            }
        }

        .r-1 {

            h2 {
                font-size: 20px;
                line-height: 26px;

                span {
                    margin-left: 0 !important;
                }
            }
        }

        .r-2 {
            margin-top: 20px;
            padding-right: 20px;

            .column-4 {
                margin-left: 0 !important;

                .text-content {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .r-3 {
            margin-top: 20px;
            padding-right: 20px;

            .column-9 {
                flex-direction: column;

                .text-item {
                    width: 100%;
                    margin-top: 30px !important;
                    margin-left: 0;
                    padding-left: 20px;
                    font-size: 14px;
                    line-height: 22px;

                    .number {
                        transform: translate(0, 0);
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

.block-text-2-c.color-bkg-white {
    background-color: $white;

    .r-1 {
        h2 {
            color: $blue-1;
        }
    }

    .r-2 {
        .column-4 {
            .text-content {
                p, ul, li, li:before, a {
                    color: $blue-1;
                }
            }
        }
    }

    .r-3 {
        .column-9 {
            .text-item {
                h3, p, ul, li, li:before, a {
                    color: $blue-1;
                }
            }
        }

        .text-item {
            .number {
                color: $blue-1;
            }
        }
    }
}
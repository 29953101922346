.block.block-jobs {
    padding: 30px 0;
    background-color: $purple;

    .column-12 {
        width: calc(100% - 60px);
        margin-left: 30px;
        background-color: $green;
        padding: 30px 100px;
    }

    .job-item {
        padding: 40px 0;
        color: $blue-1;
        border-top: 1px solid $blue-1;

        &:first-child {
            border-top: none;
        }

        .job-item__title {
            @include armin-grotesk-black(28px, 38px);
            text-transform: uppercase;
        }

        .job-item__metas {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: calc(100% - 200px);

            .meta {
                margin-left: 100px;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 0;
                }

                .icon {
                    flex: 0 0 auto;
                    width: 45px;
                    height: 45px;
                    border: 1px solid $blue-1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    svg {
                        width: 18px;
                        height: auto;
                    }
                }

                .text {
                    @include armin-grotesk-semi-bold(20px, 36px);
                    margin-left: 20px;
                }
            }
        }

        .button-circle {
            position: absolute;
            right: 0;
            bottom: 40px;
        }
    }

    @media screen and (max-width: 1280px) {
        .column-12 {
            padding: 30px 60px;
        }

        .job-item .job-item__metas {
            width: calc(100% - 140px);
        }

        .job-item .job-item__metas .meta .text {
            font-size: 16px;
            line-height: 26px;
        }

        .job-item .button-circle {
            width: 50px;
            height: 50px;

            svg {
                width: 18px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .grid {
            width: 100%;
        }

        .column-12 {
            padding: 30px;
        }

        .job-item {
            padding: 30px 0;

            .job-item__title {
                font-size: 20px;
                line-height: 1.2;
                width: calc(100% - 60px);
            }

            .job-item__metas {
                margin-top: 20px;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;

                .meta {
                    margin: 20px 0 0 0;
                    width: 100%;
                }
            }

            .button-circle {
                top: 15px;
            }
        }
    }
}
@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

@font-face {
    font-family: 'Armin Grotesk Blaack';
    src: url('../fonts/ArminGrotesk-Black.eot');
    src: local('Armin Grotesk Black'), local('ArminGrotesk-Black'),
    url('../fonts/ArminGrotesk-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ArminGrotesk-Black.woff2') format('woff2'),
    url('../fonts/ArminGrotesk-Black.woff') format('woff'),
    url('../fonts/ArminGrotesk-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Armin Grotesk Normal';
    src: url('../fonts/ArminGrotesk-NormalItalic.eot');
    src: local('Armin Grotesk Normal Italic'), local('ArminGrotesk-NormalItalic'),
    url('../fonts/ArminGrotesk-NormalItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ArminGrotesk-NormalItalic.woff2') format('woff2'),
    url('../fonts/ArminGrotesk-NormalItalic.woff') format('woff'),
    url('../fonts/ArminGrotesk-NormalItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Armin Grotesk Regular';
    src: url('../fonts/ArminGrotesk-Regular.eot');
    src: local('Armin Grotesk Regular'), local('ArminGrotesk-Regular'),
    url('../fonts/ArminGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ArminGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/ArminGrotesk-Regular.woff') format('woff'),
    url('../fonts/ArminGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Armin Grotesk Semi-Bold';
    src: url('../fonts/ArminGrotesk-SemiBold.eot');
    src: local('Armin Grotesk SemiBold'), local('ArminGrotesk-SemiBold'),
    url('../fonts/ArminGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ArminGrotesk-SemiBold.woff2') format('woff2'),
    url('../fonts/ArminGrotesk-SemiBold.woff') format('woff'),
    url('../fonts/ArminGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@mixin armin-grotesk-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Armin Grotesk Regular', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin armin-grotesk-normal($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Armin Grotesk Normal', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin armin-grotesk-semi-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Armin Grotesk Semi-Bold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin armin-grotesk-black($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Armin Grotesk Blaack', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

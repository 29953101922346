.block.block-problematic {

    .background {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        opacity: 0.5;
        z-index: 1;
        width: 46vw;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
        }
    }

    .r-1 {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        [class^="column"] {
            float: none;
        }
    }

    .c-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @include set-grid-prop(width, 4, 4);
        padding: 200px 0;

        h2 {
            position: relative;
            z-index: 2;
            @include armin-grotesk-black(36px, 46px);
            color: white;
            text-transform: uppercase;
            margin-left: 16.1290322581%;
        }

        .description {
            position: relative;
            z-index: 2;
            margin-top: 40px;
            margin-left: 25%;
            padding-right: 20px;
            @include armin-grotesk-semi-bold(26px, 40px);
        }
    }

    .c-2 {
        display: flex;
        align-content: center;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 100px;
        z-index: 2;
    }

    .link-item {
        flex: 0 0 auto;
        position: relative;
        width: 40%;
        margin-top: 80px;
        margin-left: 20%;

        &.has-link-cursor {
            cursor: none !important;
        }

        &:nth-child(2n + 1) {
            margin-left: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }

        a {
            display: block;
        }

        .number {
            @include armin-grotesk-black(18px, 18px);
            color: $blue-1;
            position: absolute;
            top: 0;
            left: -30px;
        }

        .text {
            @include armin-grotesk-black(28px, 38px);
            color: white;
            text-transform: uppercase;
        }

        .circle {
            display: none;
            @include center-y;
            right: 0;
            width: 50px;
            height: 50px;
            background-color: $blue-1;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            svg {
                width: 16px;
                line-height: 16px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .background {
            display: none;
        }

        .r-1 {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .c-1 {
            padding: 0;

            h2 {
                font-size: 20px;
                line-height: 26px;
                margin-left: 0;
            }

            .description {
                margin-top: 20px;
                font-size: 16px;
                line-height: 24px;
                margin-left: 0;
            }
        }

        .c-2 {
            padding: 0;
            margin-top: 40px;

            .link-item {
                width: 100%;
                margin-left: 0 !important;
                margin-top: 60px !important;
                padding-left: 14px;

                &:first-child {
                    margin-top: 0 !important;
                }

                .text {
                    display: block;
                    font-size: 18px;
                    line-height: 22px;
                    width: calc(100% - 80px);
                }

                .number {
                    font-size: 12px;
                    line-height: 12px;
                    left: -14px;
                }

                &.has-link-cursor {
                    .circle {
                        display: flex;
                    }
                }
            }
        }
    }

}
.block.block-head-2cols {
    .desktop-hide { display: none !important; }
    .mobile-hide { display: block !important; }
    .grid {
        padding-top: 170px;
        padding-bottom: 110px;
        height: 100%;
        /*
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        */
    }


    .row {
        padding: 0 60px;
        
        h1 {
            display: block;
            width: 100%;
            @include armin-grotesk-black(44px, 54px);
            color: white;
            text-transform: uppercase;
        }
        .r-1 {
            display: flex; 
            flex-wrap: wrap;
            flex-direction: row;
            align-content: center;
            align-items: center;
        }
        .c-1 {
            width: 50%;

            .introduction p {
                margin-top: 20px;
                @include armin-grotesk-semi-bold(26px, 36px);
            }
            .button {
                margin-top: 40px;
            }
        }
        .c-2 {
            width: 50%;

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: 100vh;

        .desktop-hide { display: block !important; }
        .mobile-hide { display: none !important; }

        .grid {
            padding-top: 80px;
            padding-bottom: 0;
        }

        .row {
            height: 100%;
            padding: 0;

            .r-1 {
                width: 100%;
                height: 100%;
            }
            h1 {
                font-size: 25px;
                line-height: 30px;
            }
            .introduction p {
                margin-top: 10px;
                @include armin-grotesk-semi-bold(16px, 25px);
            }
            .c-1 {
                width: 100%;

                img {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                }
            }
            .c-2 {
                width: 100%;
            }
        }
    }

}
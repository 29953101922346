/**
EVENT
 */
@mixin no-select() {
    cursor: default;
    user-select: none;
    -webkit-touch-callout: none;
}

/**
POSITION
 */
@mixin center-margin() {
    margin-left: auto;
    margin-right: auto;
}

@mixin full-relative() {
    position: relative;
    width: 100%;
    height: 100%;
}

@mixin full-absolute() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin full-fixed() {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin center-x() {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}

@mixin center-y() {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

@mixin center-xy() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/**
ALPHA
 */
@mixin autoAlpha($visible){
    opacity: $visible;
    @if $visible == 0 {visibility : hidden;}
    @else {visibility : visible;}
}

/**
Clearfix
 */
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
GRAYSCALE
 */
@mixin grayscale( $toggle: "on", $percentage: 1 ) {
    $svg-type: "matrix";
    $svg-value: "0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0";
    $ie-alpha: alpha(#{"opacity="}round( $percentage * 100 ) );

    @if $percentage != 1 {
        $svg-type: "saturate";
        $svg-value: $percentage;
    }

    @if $toggle == "on" {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'#{$svg-type}\' values=\'#{$svg-value}\'/></filter></svg>#grayscale"); // Firefox 10+, Firefox on Android
        filter: grayscale( round( $percentage * 100% ) ); // Catch-all
        filter: gray; // IE6-9
        -webkit-filter: grayscale( round( $percentage * 100% ) ); // Chrome 19+, Safari 6+, Safari 6+ iOS
    }

    @if $toggle == "off" {
        @if $svg-type == "saturate" {
            filter: none;
        } @else {
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
        }
        -webkit-filter: grayscale(0%);
        filter: grayscale(0);
    }
}

/**
FONTS
 */
@function get-vw($target) {
    $vw-context: (1600 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}

@mixin set-vw($prop, $target) {
    $vw-context: (1600 * 0.01) * 1px;
    $return: ($target / $vw-context) * 1vw;
    @media (min-width: 1300px) {
        #{$prop}: $target !important;
    }
    #{$prop}: $return;
}

/**
GRID
 */
.right { float: right; }
.left { float:left; }

.cf{ @include clearfix; }

.d-table {
    display: table;
    width: 100%;
    height: 100%;
}

.d-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}


.section-answer {
    background-color: $blue-1;
    padding: 100px 0 80px 0;
    overflow: hidden;

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            right: -90px;
            transform: translate3d(0, -50%, 0.1px);

            svg {
                width: 664px;
                height: 1155px;
            }
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(-50px * #{$i}), -50%, 0.1px);
                }
            }
        }
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        .column-10 {
            @include set-grid-prop(margin-left, 1, 0);
        }

        .title {
            @include armin-grotesk-black(36px, 46px);
            color: $white;
            text-transform: uppercase;

            .l-2 {
                margin-left: 60px;
            }
        }
    }

    .r-2 {
        $gutter: get-grid-percent(0.5, 0);

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            color: white;

            .description {
                margin-top: 30px;
                @include armin-grotesk-semi-bold(19px, 31px);
                max-width: 540px;
            }

            ul {
                margin-top: 60px;
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    flex: 0 0 auto;
                    width: calc(50% - #{$gutter});
                    position: relative;
                    margin-bottom: calc(#{$gutter} * 2);

                    .number {
                        position: absolute;
                        left: -30px;
                        top: 4px;
                        @include armin-grotesk-black(18px, 18px);
                        color: $white;
                    }

                    .content {
                        @include armin-grotesk-semi-bold(19px, 31px);
                        padding-right: 30px;
                    }
                }
            }
        }

    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .background {
            opacity: 0.4;

            .main,
            .slave {
                right: -600px;

                svg {
                    width: 744px;
                    height: 1296px;
                }
            }
        }

        .r-1 {

            .title {
                font-size: 20px;
                line-height: 25px;

                .l-1 {
                    margin-left: 30px;
                }

                .l-2 {
                    margin-left: 0;
                }
            }
        }

        .r-2 {

            .column-10 {

                .description {
                    font-size: 14px;
                    line-height: 22px;
                }

                ul {
                    flex-direction: column;
                    margin-top: 30px;

                    li {
                        width: 100%;
                        margin-bottom: 30px;
                        padding-left: 20px;

                        .number {
                            left: 0;
                            font-size: 12px;
                            line-height: 12px;
                        }

                        .content {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}
#project {

    section {
        position: relative;
    }

    @import "project/section-hero";
    @import "project/section-answer";
    @import "project/section-medias";
    @import "project/section-highlight";
    @import "project/section-numbers";
    @import "project/section-quote";
    @import "project/section-related";
    @import "project/section-text";

}
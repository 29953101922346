.section-medias {
    background-color: $purple;

    .grid,
    .row {
        width: 100%;
        max-width: 100%;
    }

    .media-line {
        overflow: hidden;
        height: 50vh;
        cursor: none !important;

        .media-line__wrapper {
            display: inline-flex;
            align-items: stretch;
            justify-content: flex-start;
            height: 100%;
            cursor: none !important;
        }

        .media-item {
            position: relative;
            flex: 0 0 auto;
            height: 100%;
            width: calc(var(--aspect-ratio) * 50vh);

            video,
            img {
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            
            &.video {
                cursor: pointer;

                video {
                    position: relative;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }

                html.desktop & {
                    &:hover {
                       .icon {
                           &:before {
                               transform: scale(1.1);
                           }
                       }
                    }
                }
            }

            .icon {
                @include center-xy;
                width: 96px;
                height: 96px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $red;
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    z-index: 1;
                    transition: transform 0.6s $easeInOutQuart;
                }

                svg {
                    width: 25px;
                    height: auto;
                    fill: white;
                    z-index: 2;
                }
            }
        }

        &.m-2 {
            .media-line__wrapper {
                justify-content: flex-end;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .media-line .media-item.video {
            video {
                display: none;
            }
        }
    }
}
.block.block-page-end {
    background-color: $white;
    padding: 200px 0;
    overflow: hidden;

    &.type-1 {
        .b-1 {
            display: block;
        }
    }

    &.type-2 {
        .b-2 {
            display: block;
        }
    }

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;
        display: none;
        opacity: 0.6;

        &.b-1 {

            .slave {

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(-50px * #{$i}), -50%, 0.1px);
                    }
                }
            }
        }

        &.b-2 {

            .main,
            .slave {
                @include set-grid-prop(left, 4.5, 5);
            }

            .slave {

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate3d(calc(50px * #{$i}), -50%, 0.1px);
                    }
                }
            }
        }

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            right: -90px;
            transform: translate3d(0, -50%, 0.1px);

            svg {
                width: 664px;
                height: 1155px;
            }
        }
    }

    .row {
        z-index: 2;
    }

    .r-1 {

        .title {
            @include armin-grotesk-black(70px, 80px);
            text-transform: uppercase;
            color: $blue-1;

            span {
                display: block;
            }

            .l-1 {
                @include set-grid-prop(margin-left, 0, 1);
            }

            .l-2 {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .l-3 {
                @include set-grid-prop(margin-left, 0, 1);
            }
        }
    }

    .r-2 {
        margin-top: 40px;

        .column-5 {
            @include set-grid-prop(margin-left, 1, 1);
            @include armin-grotesk-semi-bold(26px, 36px);
            color: $blue-1;
        }
    }

    .r-3 {
        margin-top: 40px;

        .button {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .background {
            opacity: 0.6;

            .main,
            .slave {
                width: 480px;
                height: 853px;

                svg {
                    width: 480px;
                    height: 853px;
                }
            }

            &.b-1 {
                .main,
                .slave {
                    left: 50%;
                }
            }

            &.b-2 {
                .main,
                .slave {
                    left: -100px;
                }
            }
        }

        .r-1 {

            .title {
                font-size: 30px;
                line-height: 1.2;

                span {
                    margin-left: 0 !important;
                }
            }
        }

        .r-2 {
            margin-top: 30px;

            .column-5 {
                margin-left: 0;
                font-size: 16px;
                line-height: 1.2;
            }
        }

        .r-3 {
            margin-top: 30px;

            .button {
                margin-left: 0;
            }
        }
    }
}
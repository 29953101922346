.text-content {
    @include armin-grotesk-regular(19px, 31px);
    color: $white;

    h1 {
        color: $white;
        @include armin-grotesk-black(44px, 44px);
        text-transform: uppercase;
    }

    h2 {
        @include armin-grotesk-black(36px, 46px);
        color: $white;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        @include armin-grotesk-black(26px, 36px);
        color: $white;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        @include armin-grotesk-black(22px, 32px);
        color: $white;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul,
    ol,
    p {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        padding-left: 40px;

        li {
            margin-top: 10px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: -34px;
                top: 11px;
                background-color: $white;
                border-radius: 50%;
                width: 6px;
                height: 6px;
            }
        }
    }

    a {
        transition: color 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                color: $green;
            }
        }
    }
}

p {

    b,
    strong {
        @include armin-grotesk-black();
    }

    em,
    i {
       font-style: italic;
    }

    a {
        text-decoration: underline;
    }
}

h2 {
    @include armin-grotesk-black(36px, 46px);
    text-transform: uppercase;
}

/**
OUTLINE
 */
.outline {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
}

/**
SUCCESS ITEM
 */

.list-stories {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.storie-item {
    flex: 0 0 auto;
    @include set-grid-prop(margin-left, 1, 0);
    width: calc(100% / 3);

    &:nth-child(3n + 1){
        margin-left: 0;
    }

    html.desktop & {

        &:hover {

            .thumbnail {
                .s-1 {
                    transform: translate(16px, -16px);
                }

                .s-2 {
                    transform: translate(32px, -32px);
                }
            }
        }
    }

    a {
        display: block;

        &, &:visited, &:focus {
            color: $blue-1;
        }
    }

    .thumbnail {
        position: relative;

        .img-responsive__item {
            overflow: visible;
        }

        img {
            z-index: 10;
        }

        span {
            pointer-events: none;
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            border: 2px solid $blue-1;
            background-color: $white;
            left: 0;
            top: 0;
            transition: transform 0.6s $easeOutQuart;

            &.s-1 {
                z-index: 2;
            }

            &.s-2 {
                z-index: 1;
            }
        }
    }

    /*.top-tag {
        pointer-events: none;
        position: absolute;
        height: 40px;
        padding: 0 20px;
        z-index: 20;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 15px;
        left: 20px;
        padding-top: 4px;

        .text {
            @include armin-grotesk-black(14px, 14px);
            color: $blue-1;
        }
    }*/

    .tags {
        margin-top: 20px;

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-left: -14px;

            li {
                margin-left: 8px;
                margin-bottom: 14px;
                border-radius: 20px;
                height: 40px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $blue-1;
                transition: background-color 0.6s $easeOutQuart;

                /*html.desktop & {
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }*/

                .color {
                    width: 8px;
                    height: 8px;
                    background-color: white;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .text {
                    @include armin-grotesk-black(14px, 24px);
                    color: $blue-1;
                    padding-top: 4px;
                }
            }
        }
    }

    .title {
        margin-top: 10px;
        @include armin-grotesk-black(18px, 30px);
        color: $blue-1;
        text-transform: uppercase;
    }

    .excerpt {
        @include armin-grotesk-semi-bold(18px, 30px);
        color: $blue-1;
        margin-top: 10px;
    }

    &.insight {

        .thumbnail {

            span {
                background-color: $purple !important;
                border-color: $yellow !important;
            }
        }

        .metas {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include armin-grotesk-black(18px, 28px);
            color: $blue-1;

            .type {
                text-transform: uppercase;
            }

            .date {
                margin-left: 20px;
            }
        }

        .title {
            text-transform: unset;
            @include armin-grotesk-semi-bold(18px, 30px);
        }
    }
}

/**
NUMBER ITEM
 */
.number-item {
    width: calc(90% / 3);
    margin-left: 5%;
    flex: 0 0 auto;

    &:first-child {
        margin-left: 0;
    }

    .number-item__top {
        position: relative;
        display: inline-block;

        .number-item__before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-150%, -50%);
            @include armin-grotesk-semi-bold(40px, 40px);
            color: $white;
        }

        .number-item__content {
            @include armin-grotesk-black(150px, 100px);
            color: $blue-1;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $white;

            .main {
                position: relative;
                z-index: 10;
            }

            .slave {
                position: absolute;
                top: 0;
                left: 0;

                @for $i from 1 through 9
                {
                    &.s-#{$i} {
                        z-index: 10 - $i;
                        transform: translate(calc(10px * #{$i}), calc(10px * #{$i}));
                    }
                }
            }
        }

        .number-item__after {
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translate(100%, -50%);
            @include armin-grotesk-semi-bold(40px, 40px);
            color: $white;
        }
    }

    .number-item__bottom {
        margin-top: 60px;
        @include armin-grotesk-semi-bold(19px, 31px);
        color: white;
        max-width: 270px;
     }
}

@media screen and (max-width: $mobile){

    .text-content {
        font-size: 16px;
        line-height: 1.4;

        h2 {
            font-size: 20px;
            line-height: 25px;
            margin-top: 20px;
        }

        h3 {
            font-size: 18px;
            line-height: 36px;
            margin-top: 20px;
        }

        h4 {
            font-size: 16px;
            line-height: 32px;
            margin-top: 20px;
        }

        ul,
        ol,
        p {
            margin-top: 20px;
        }
    }

    h4,
    h2 {
        font-size: 20px;
        line-height: 25px;
    }

    .outline {
        -webkit-text-stroke-width: 1px;
    }

    .storie-item {

        .tags {
            padding-left: 6px;

            ul {

                li {
                    height: 35px;

                    .text {
                        padding-top: 2p;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
        }

        .title {
            font-size: 16px;
            line-height: 26px;
        }

        .excerpt {
            font-size: 14px;
            line-height: 22px;
            padding-right: 30px;
        }

        &.insight {

            .metas {
                font-size: 16px;
                line-height: 26px;
            }

            .title {
                font-size: 14px;
                line-height: 22px;
                padding-right: 30px;
            }
        }
    }

    .number-item {
        width: auto;
        margin-top: 50px;
        margin-left: 0;

        &:first-child {
            margin-top: 0;
        }

        .number-item__top {

            .number-item__content {
                font-size: 90px;
                line-height: 68px;

                .number-item__before,
                .number-item__after {
                    font-size: 30px;
                }

                .number-item__after {
                    right: -25px;
                }

                .slave {
                    position: absolute;
                    top: 0;
                    left: 0;

                    @for $i from 1 through 9
                    {
                        &.s-#{$i} {
                            z-index: 10 - $i;
                            transform: translate(calc(5px * #{$i}), calc(5px * #{$i}));
                        }
                    }
                }
            }
        }

        .number-item__bottom {
            margin-top: 20px;
        }
    }
}
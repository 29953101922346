#footer {
    background-color: $blue-1;
    padding: 60px 0 100px 0;

    &:before {
        content: "";
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        height: 1px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0.09) 100%);
    }

    .r-0 {
        padding-bottom: 60px;
        @include armin-grotesk-regular(15px, 15px);
        color: white;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);

        }
    }

    .r-1 {

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        .c-1 {

            .logo {

                svg {
                    width: 196px;
                    height: auto;
                }
            }

            .address {
                margin-top: 40px;
                @include armin-grotesk-semi-bold(16px, 32px);
                color: white;
            }

            .button {
                margin-top: 30px;
            }

            ul {
                margin-top: 3rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    width: 50px;
                    height: 50px;
                    margin-left: 20px;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        width: 50px;
                        height: 50px;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: white;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            z-index: 1;
                            transition: transform 0.6s $easeInOutQuart;
                        }

                        svg {
                            z-index: 2;
                        }

                        html.desktop & {
                            &:hover {
                                &:before {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }

                    &.instagram {
                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    &.linkedin {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    &.youtube {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        .c-2 {

            .legend {
                @include armin-grotesk-black(16px, 26px);
                color: white;
            }

            ul {
                @include armin-grotesk-normal(16px, 26px);
                color: white;
                margin-bottom: 2rem;
            }

        }

        .c-3 {

            .legend {
                @include armin-grotesk-black(16px, 26px);
                color: white;
            }

            ul {
                @include armin-grotesk-normal(16px, 26px);
                color: white;
                margin-bottom: 2rem;
            }

        }

        .c-4 {

            display: flex;
            flex-direction: column;

            .elt-1 {
                .legend {
                    @include armin-grotesk-black(16px, 26px);
                    color: white;
                    text-transform: uppercase;

                    .l-2 {
                        margin-left: 20px;
                    }
                }

                .button-icon {
                    margin-top: 40px;
                }
            }

            .elt-2 {
                .cmc-widget {
                    margin-top: 3rem;
                }
            }

        }
    }

    .r-2 {
        margin-top: 50px;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .cookies {
            @include armin-grotesk-regular(15px, 15px);
            color: white;
            opacity: 0.4;

            html.desktop & {
                &:hover {
                    opacity: 1;
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
                margin-left: 25px;
                @include armin-grotesk-regular(15px, 15px);
                color: white;
                opacity: 0.4;
                transition: opacity 0.6s $easeOutQuart;

               /* &:first-child {
                    margin-left: 0;
                }*/

                html.desktop & {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 45px 0;

         .r-1 {

            .column-10 {
                flex-direction: column;
                align-items: stretch;
            }

             .c-1 {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 flex-direction: column;

                 .logo {
                     svg {
                         width: 158px;
                     }
                 }

                 .address {
                     margin-top: 20px;
                     font-size: 14px;
                     line-height: 22px;
                     text-align: center;
                 }

                 .button {
                     margin-top: 20px;
                 }

                 ul {
                     justify-content: center;
                 }
             }

             .c-2 {
                  margin-top: 40px;
                  padding-top: 40px;
                  border-top: 1px solid rgba(255, 255, 255, 0.1);
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  text-align: center;

                  .legend {
                      margin-left: 0;
                  }
              }

             .c-3 {
                 padding-top: 40px;
                 border-top: 1px solid rgba(255, 255, 255, 0.1);
                 align-items: center;
                 justify-content: center;
                 flex-direction: column;
                 text-align: center;

                 .legend {

                     margin-left: 0;
                 }


             }

             .c-4 {
                 display: flex;
                 padding-top: 40px;
                 border-top: 1px solid rgba(255, 255, 255, 0.1);
                 align-items: center;
                 justify-content: center;
                 flex-direction: column-reverse;
                 text-align: center;

                 .elt-1 {
                     margin-top: 0;
                     text-align: center;
                 }

                 .elt-1 {
                     margin-top: 2em;
                     text-align: center;
                 }

                 .button-icon {
                     margin: 20px auto 0 auto;

                     a {
                         position: relative;
                         margin: 0 auto;
                     }
                 }
             }
         }

        .r-2 {

            ul {
                justify-content: center;

                li {
                    font-size: 13px;
                }
            }
        }
    }
}
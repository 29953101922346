.block.block-team {
    padding: 100px 0 60px 0;
    background-color: $purple;

    .r-1 {
        text-align: center;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        h2 {
            @include armin-grotesk-black(36px, 46px);
            color: $yellow;
        }
    }

    .r-2 {


        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);

            .solutions-container {
                padding-top: 100px;
            }

            .solutions-container,
            .solutions-container__wrapper {
                width: 100%;
            }

            .solutions-container__wrapper {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }

        .solution-item {
            width: calc((100% - 150px) / 4);
            margin-left: 50px;
            margin-bottom: 50px;
            text-align: center;

            &:nth-child(4n + 1) {
                margin-left: 0;
            }

            .solution-item__circle {
                border-radius: 50%;
                width: 80%;
                margin-left: 10%;
                border: 1px solid $white;
                text-align: center;

                .solution-item__img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    overflow: hidden;
                    z-index: 2;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }

                span {
                    padding: 26px 20px 20px 20px;
                    @include center-xy;
                    @include armin-grotesk-black(25px, 35px);
                    color: $white;
                    text-transform: uppercase;
                }

                .solution-item__over {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    z-index: 14;
                    transition: opacity 0.6s $easeOutQuart;

                    svg {
                        @include center-xy;
                        width: 36px;
                        height: 36Px;
                    }
                }

                .main {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: $white;
                    z-index: 12;
                    transition: opacity 0.6s $easeOutQuart;
                }

                .slave {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    background-color: $purple;
                    border: 1px solid $white;
                    opacity: 0;
                    transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                    z-index: 2;

                    @for $i from 1 through 4
                    {
                        &.s-#{$i} {
                            z-index: 10 - $i;
                            //transform: translate3d(calc(14px * #{$i}), calc(10px * #{$i}), 0.1px);
                        }
                    }
                }
            }

            .solution-item__description {
                margin-top: 40px;
                @include armin-grotesk-semi-bold(20px, 30px);
                text-align: center;

                .name {
                    @include armin-grotesk-black(20px, 30px);
                    color: $white;
                    text-transform: uppercase;
                }

                .function {
                    margin-top: 6px;
                }
            }

            .button {
                display: none;
                margin-top: 40px;
            }

            html.desktop & {

                a {
                    &:hover {

                        .solution-item__circle {

                            .solution-item__over {
                                opacity: 1;
                            }

                            .main {
                                opacity: 1;
                            }

                            .slave {

                                @for $i from 1 through 4
                                {
                                    &.s-#{$i} {
                                        opacity: 1;
                                        transform: translate(calc(14px * #{$i}), calc(-10px * #{$i}));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .r-3 {
        margin-top: 40px;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            text-align: center;
        }
    }

    @media screen and (max-width: 1280px) {
        .r-2 .solution-item .solution-item__circle span {
            font-size: 16px;
            line-height: 26px;
        }

        .r-2 .solution-item .solution-item__description {
            font-size: 16px;
            line-height: 26px;
        }

        .r-2 .solution-item .solution-item__description .name {
            font-size: 18px;
            line-height: 28px;
        }

        .r-3 {
            margin-top: 50px;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-1 {

            h2 {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .r-2 {
            margin-top: 40px;

            .column-10 {

                .solutions-container {
                    padding-top: 0;
                }

                .solutions-container__wrapper {
                    flex-direction: column;
                    align-items: center;
                }
            }

            .solution-item {
                width: 100%;
                margin: 0 0 40px 0 !important;

                .solution-item__circle {
                    max-width: 180px;
                    margin: 0 auto;
                }

                .solution-item__description {
                    margin-top: 20px;
                }

                &.has-link {
                    .button {
                        display: inline-block;
                    }
                }
            }
        }
    }

}
#contact {

    form {
        h1 {
            display: none;
        }

        .ffields {
            color: white;

            h2 {
                margin-bottom: 20px;
                @include armin-grotesk-semi-bold(26px, 26px);
            }

            #mg-edit_content {
                @include armin-grotesk-regular(16px, 26px);
            }
        }
    }

    #error {
        color: $red;
        @include armin-grotesk-regular(19px, 19px);
        padding: 10px 0;
    }

    .bforms {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: -50px;

        .tr {
            width: calc(50% - 50px);
            margin-left: 50px;
            margin-bottom: 50px;
            @include armin-grotesk-regular(19px, 31px);
            color: $green;

            .c1 {
                margin-bottom: 10px;

                &.error {
                    color: $red;

                    + .c2 {
                        textarea,
                        input {
                            border-color: $red;
                        }
                    }
                }
            }

            textarea,
            input[type="text"] {
                border: 1px solid $blue-1;
                border-radius: 8px;
                height: 50px;
                width: 100%;
                padding: 0 10px;
                background-color: $blue-1;
            }

            textarea {
                padding: 20px 10px;
                height: 240px;
                resize: none;
            }
        }

        #merge-6 {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .legal__content {
        @include armin-grotesk-semi-bold(14px, 24px);
        color: white;
        margin-bottom: 40px;
    }

}

/*****
HUBSPOT
 */
.hbspt-form {

    form {
        display: flex;
        flex-direction: column;
    }

    .form-columns-2 {
        float: none;
        display: flex;
        width: 100%;
        max-width: 100%;

        .hs-form-field {
            margin-top: 0 !important;
            width: calc(50% - 50px);
            margin-left: 50px;
            margin-bottom: 50px;

            &:first-child {
                margin-left: 0;
            }
        }

        .input {
            margin-right: 0 !important;
        }
    }

    .hs-form-field + .hs-form-field {
        margin-top: 20px;
    }

    .form-columns-1 {
        float: none;
        display: flex;
        width: 100%;
        max-width: 100%;

        .hs-form-field {
            width: 100% !important;
        }
    }

    textarea,
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
        border: 1px solid $green;
        border-radius: 8px;
        height: 50px;
        width: 100%;
        padding: 0 10px;
        background-color: $blue-1;
        @include armin-grotesk-regular(19px, 31px);
        color: $green;
    }

    textarea {
        padding: 20px 10px;
        height: 240px;
        resize: none;
        width: 100% !important;
    }

    .hs-richtext {
        margin-top: 30px;
        @include armin-grotesk-semi-bold(14px, 24px);
        color: white;
        margin-bottom: 40px;
    }

    .hs-error-msgs,
    .hs-main-font-element,
    .hs-error-msg {
        color: $red;
        @include armin-grotesk-regular(19px, 19px);
        padding: 10px 0 0 0;

        li {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    input[type="submit"] {
        margin-top: 30px;
        height: 50px;
        padding: 0 30px;
        font-family: "Armin Grotesk Regular", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $blue-1;
        padding-top: 5px;
        background-color: $white;
        border-radius: 30px;
        transition: background-color 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                background-color: $green;
            }
        }
    }

    .submitted-message {
        @include armin-grotesk-semi-bold(20px, 28px);
        color: $blue-1;
        text-align: center;
    }

    @media screen and (max-width: $mobile) {
        .form-columns-2 {
            flex-direction: column;

            .hs-form-field {
                margin-left: 0;
                margin-bottom: 20px;
            }
        }

        .hs-error-msgs, .hs-main-font-element, .hs-error-msg {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

#single {

    .hbspt-form textarea, .hbspt-form input[type=text], .hbspt-form input[type=email], .hbspt-form input[type=tel] {
        background-color: $green;
        border-color: $blue-1;
        color: $blue-1;
    }

    .hbspt-form .submitted-message {
        color: $blue-1;
    }

    .hbspt-form input[type=submit] {
        background-color: $blue-1;
        color: $green;

        html.desktop & {
            &:hover {
                background-color: white;
            }
        }
    }

    .hs-form-field {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }
}

/*****
SELECT
 */
.choices {
    border: none;
    border-radius: 0;

    &.is-focused {
        border: none;
        border-radius: 0;
    }
}

.choices.is-open.is-focused {
    z-index: 1000 !important;
    border: none;
    border-radius: 0;
}

.choices__inner {
    background-image: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $blue-1;
    box-shadow: none;
    border-radius: 0;
}

.choices__inner {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $blue-1;
    box-shadow: none;
    border-radius: 0;
}

.choices__list--single .choices__item {
    @include armin-grotesk-semi-bold(26px, 36px);
    color: $blue-1;

    &::placeholder {
        opacity: 0.5;
    }
}

.choices__list--dropdown {
    border: none;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
}

.choices__list--dropdown .choices__list {
    @include armin-grotesk-semi-bold(20px, 30px);
    color: $blue-1;
    padding: 10px;
}

.choices.is-open[data-type*='select-one']:after  {
    transform: rotate(-180deg);
}

.choices[data-type*='select-one']:after {
    margin-top: -6px;
    width: 15px;
    height: 15px;
    border: none;
    background: url('../images/common/arrow.svg') no-repeat center center;
}

.choices[data-type*='select-one'] .choices__inner {
    padding-left: 0;
}

.choices__list--single {
    padding-left: 0;
}

@media screen and (max-width: 1280px) {
    .choices__inner {
        padding-left: 4px;
    }

    .choices__list--single {
        padding-left: 0;
    }

    .choices__list--single .choices__item {
        font-size: 16px;
        line-height: 26px;
    }

    .choices__list--dropdown .choices__item {
        font-size: 12px;
        word-break: keep-all;
    }
}
.block.block-skills {
    background-color: $white;
    padding-bottom: 50px;

    .r-1 {
        padding: 100px 0;

        span {
            color: $blue-1;
            display: block;
            font-size: 44px;
            line-height: 64px;
        }

        .l-1 {
            @include set-grid-prop(margin-left, 0, 1);
        }

        .l-2 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .l-3 {
            @include set-grid-prop(margin-left, 0, 1);
        }
    }

    .r-item {
        padding: 50px 0;
        display: flex;
        align-items: center;

        [class^="column"] {
            float: none;
        }

        h2 {
            position: relative;
            color: $blue-1;

            span {
                display: block;
            }

            .number {
                position: absolute;
                @include armin-grotesk-black(18px, 18px);
                color: $blue-1;
                top: 0;
                left: -30px;
            }
        }
    }

    .container-lottie {
        flex: 0 0 auto;
        @include set-grid-prop(margin-left, 1, 1);
    }

    .container-text {
        @include set-grid-prop(width, 5, 5);
        margin-left: auto;
        flex: 0 0 auto;
        @include set-grid-prop(margin-right, 1, 1);

        .text-content {
            margin-top: 20px;
            @include armin-grotesk-semi-bold(18px, 30px);
            color: $blue-1;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-1 {
            padding: 60px 0;

            h2 {

                span {
                    display: inline;
                    font-size: 25px;
                    line-height: 36px;
                    margin-left: 0 !important;
                }
            }
        }

        .r-item {
            margin-bottom: 50px;
            padding: 0;
            flex-direction: column;

            .container-text {
                margin-top: 30px;
                margin-right: 0;
                padding-left: 20px;
            }
        }
    }
}
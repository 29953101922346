.section-skills {
    cursor: pointer;
    height: 760px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background-color: $yellow;

    .button.main {
        position: absolute;
        top: 50px;
        right: 50px;
        z-index: 2;
    }

    .skill-item {
        width: calc(100% / 3);
        height: 100%;
        position: relative;
        flex: 0 0 auto;

        .background {
            @include full-absolute;
            transition: opacity 0.6s $easeOutQuart;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
            height: 100%;
            width: 1px;
            z-index: 10;
            opacity: 0.5;
        }

        &.s-1 {
            &:before {
                display: none;
            }
        }

        .skill-item__inner {
            height: 100%;
        }

        .content {
            position: absolute;
            top: calc(100% - 110px);
            transition: transform 0.6s $easeOutQuart;
        }

        .skill-item__wrapper {
            padding: 40px;
            height: 100%;

            .background {
                pointer-events: none;
                opacity: 0;
                mix-blend-mode: multiply;
            }

            .sector {
                @include armin-grotesk-black(14px, 14px);
                color: white;
            }

            .title {
                margin-top: 15px;
                @include armin-grotesk-black(36px, 46px);
                color: white;
                text-transform: uppercase;
                opacity: 0.6;
                transition: opacity 0.6s $easeOutQuart;
            }

            .description {
                opacity: 0;
                margin-top: 30px;
                @include armin-grotesk-semi-bold(25px, 35px);
                color: white;
                padding-right: 40px;
                transition: opacity 0.6s $easeOutQuart;
            }

            .button {
                display: none;
                margin-top: 40px;
            }
        }

        &.selected {

            .background {
                opacity: 0.5;
            }

            .content {
                transform: translate(0, -100%);
            }

            .title {
                opacity: 1;
            }

            .description {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: auto;
        align-items: stretch;
        justify-content: flex-end;
        flex-direction: column;

        .button.main {
            display: none;
        }

        .skill-item {
            width: 100%;
            height: auto;
            background-color: #e0a92f;

            &:before {
                display: none;
            }

            .skill-item__wrapper {

                .background {
                    opacity: 1;
                }

                .content {
                    position: relative;
                    top: 0;
                    transform: translate(0, 0) !important;
                }

                .title {
                    font-size: 20px;
                    line-height: 25px;
                    opacity: 1;
                }

                .description {
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    opacity: 1;
                }

                .button {
                    display: inline-block;
                }
            }

            &.s-2 {
                background-color: #e2271b;
            }

            &.s-3 {
                background-color: #00a565;
            }
        }
    }
}
#insights {
    padding: 200px 0 100px 0;
    background-color: $white;

    .section-head {
        position: relative;
        z-index: 2;

        .r-1 {

            h1 {
                @include armin-grotesk-black(44px, 54px);
                color: $blue-1;
                text-transform: uppercase;

                span {
                    display: block;
                }

                .l-1 {
                    @include set-grid-prop(margin-left, 0, 1);
                }

                .l-2 {
                    @include set-grid-prop(margin-left, 1, 1);
                }
            }
        }

        .r-2 {
            margin-top: 60px;

            .column-10 {
                @include set-grid-prop(margin-left, 1, 1);
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .legend {
                    @include armin-grotesk-black(26px, 36px);
                    color: $blue-1;
                    text-transform: uppercase;
                    padding-top: 6px;
                }

                .button {
                    margin-left: 30px;
                    @include autoAlpha(0);
                }
            }
        }

        .r-3 {
            margin-top: 30px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            [class^="column"] {
                flex: 0 0 auto;
                float: none;
            }

            .choices {
                @include set-grid-prop(width, 2, 3);
                @include set-grid-prop(margin-left, 1, 0);

                &:first-child {
                    @include set-grid-prop(margin-left, 1, 1);
                }
            }

            .button-circle {
                flex: 0 0 auto;
                @include set-grid-prop(margin-left, 1, 0);
            }
        }

    }

    .section-content {
        position: relative;
        z-index: 1;

        .loader {
            pointer-events: none;
            @include center-x;
            z-index: 100;
            top: 20px;
            @include autoAlpha(0);

            img {
                width: 60px;
                height: auto;
            }
        }

        .no-result {
            opacity: 0;
            @include armin-grotesk-regular(20px, 20px);
            pointer-events: none;
            @include center-x;
            z-index: 100;
            top: 40px;
        }

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .container-projects {
            overflow: hidden;
            padding-top: 50px;
        }

        .container-projects__inner {
            $marge: get-grid-percent(2, 0);
            $bottom: get-grid-percent(1.5, 0);
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;

            .storie-item {
                width: calc((100% - #{$marge}) / 3);
                margin-bottom: $bottom;

                .thumbnail span {
                    border-color: $blue-1 !important;
                    background-color: $white !important;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 130px 0 50px 0;

        .section-head {

            .r-1 {

                h1 {
                    font-size: 26px;
                    line-height: 36px;

                    span {
                        margin-left: 0 !important;
                    }
                }
            }

            .r-2 {
                margin-top: 30px;

                .column-10 {

                    .legend {
                        font-size: 20px;
                        line-height: 20px;
                    }

                    .button {
                        margin-left: auto;
                    }
                }

            }

            .r-3 {
                flex-direction: column;

                .choices {
                    width: 100%;
                    margin-left: 0 !important;
                    margin-top: 20px;
                    z-index: 2;
                }

                .button-circle {
                    margin-top: 40px;
                    margin-left: auto;
                }
            }
        }

        .section-content {

            .container-projects__inner {
                flex-direction: column;

                .storie-item {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 40px;
                }
            }

            .no-result {
                font-size: 16px;
                line-height: 1;
                width: 100%;
                text-align: center;
            }
        }
    }
}
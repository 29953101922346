.section-hero {
    padding: 23vh 0;
    background-color: $blue-1;
    display: flex;
    align-items: center;
    justify-content: center;

    .background {
        @include full-absolute;
        overflow: hidden;
        z-index: 1;

        .mask {
            @include full-absolute;
            z-index: 3;
            background: url('../images/svgs/mask.svg') no-repeat center center;
            background-size: cover;
            opacity: 0;
        }

        &:before {
            content: "";
            background-color: rgba(31, 31, 31, 0.55);
            @include full-absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
/*            object-fit: cover;
            width: 100%;
            height: 100%;*/
            z-index: 2;
        }

        video,
        img {
            /*position: absolute;*/
            left: 0;
            top: 0;
            object-fit: cover;
            width: 120%;
            height: 120%;
        }

        video {
            //display: none;
            z-index: 2;
        }


    }

    .grid {
        z-index: 2;
    }

    .column-5 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-1 {

        .tags {

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-left: -14px;

                li {
                    margin-left: 14px;
                    margin-bottom: 14px;
                    border-radius: 20px;
                    height: 40px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    transition: background-color 0.6s $easeOutQuart;

                    html.desktop & {
                        &:hover {
                            background-color: rgba(255, 255, 255, 0.2);
                        }
                    }

                    .color {
                        width: 8px;
                        height: 8px;
                        background-color: white;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .text {
                        @include armin-grotesk-black(14px, 24px);
                        color: white;
                        padding-top: 4px;
                    }
                }
            }
        }
    }

    .r-2 {
        margin-top: 40px;

        h1 {
            @include armin-grotesk-black(44px, 54px);
            color: white;
            text-transform: uppercase;
        }
    }

    .r-3 {
        margin-top: 40px;
        @include armin-grotesk-semi-bold(26px, 36px);
        color: white;
    }

    .r-4 {
        margin-top: 60px;
    }

    .r-5 {

        .column-4 {
            @include set-grid-prop(margin-left, 6, 7);
            @include set-grid-prop(width, 4, 4);
            @include armin-grotesk-semi-bold(26px, 36px);
            color: white;
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 120px;
        padding-bottom: 80px;

        .background {
            .mask {
                opacity: 1;
            }
        }

        .r-1 {

            .tags {
                ul {
                    li {
                        height: 35px;

                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .r-2 {
            margin-top: 20px;

            h1 {
                font-size: 25px;
                line-height: 1;
            }
        }

        .r-3 {
            margin-top: 20px;
            font-size: 16px;
            line-height: 24px;
        }

        .r-4 {
            margin-top: 30px;
        }

        .r-5 {
            margin-top: 20px;

            .column-4 {
                padding-left: calc(50vw - 40px);
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

}
.block.block-video {
    background-color: $blue-1;

    &.video-container {
        cursor: none !important;
    }

    .background {
        position: relative;
        z-index: 1;

        .icon {
            display: none;
            @include center-xy;
            width: 46px;
            height: 46px;
            align-items: center;
            justify-content: center;
            z-index: 3;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: $red;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                z-index: 1;
                transition: transform 0.6s $easeInOutQuart;
            }

            svg {
                width: 18px;
                height: auto;
                fill: white;
                z-index: 2;
            }
        }
    }

    .grid {
        @include center-x;
        top: 0;
        height: 100%;
        z-index: 2;

        .row,
        .column-12 {
            height: 100%;
        }

        .title {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 80px;
            @include armin-grotesk-black(36px, 46px);
            text-transform: uppercase;

            span {
                display: block;
                @include set-grid-prop(margin-left, 0, 1);
            }

            .l-2 {
                @include set-grid-prop(margin-left, 1, 1);
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-bottom: 60px;

        &.video-container {
            .background {

                .icon {
                    display: flex;
                }
            }
        }

        .grid {
            margin-top: 30px;
            position: relative;
            height: auto;
            transform: translate(0, 0);
            left: auto;

            .row,
            .column-12 {
                height: auto;
            }

            .title {
                font-size: 20px;
                line-height: 24px;
                padding-bottom: 0;

                span {
                    margin-left: 0;

                }

                .l-2 {
                    margin-left: 0;
                }
            }
        }
    }

}
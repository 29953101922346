#error-404  {
    background-color: $blue-1;

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 50%;
            @include set-grid-prop(right, 1, 1);
            transform: translate3d(0, -50%, 0.1px);
            @include armin-grotesk-black(430px, 330px);
            color: $blue-1;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $white;
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(30px * #{$i}), calc(-50% + (20px * #{$i})), 0.1px);
                }
            }
        }
    }

    .row {
        z-index: 2;
    }

    .section-content {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .column-3 {
            @include set-grid-prop(margin-left, 1, 1);

            .line-1 {
                @include armin-grotesk-black(44px, 54px);
                color: $white;
                text-transform: uppercase;
            }

            .line-2 {
                margin-top: 30px;
                @include armin-grotesk-semi-bold(19px, 31px);
                color: white;
            }

            .button {
                margin-top: 50px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: 80vh;

        .background {
            display: none;
        }
    }
}
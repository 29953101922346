/*********************
BASE
*********************/
@import "base/normalize";
@import "base/reset";
@import "base/ress";

/*********************
HELPERS
*********************/
@import "helpers/functions";
@import "helpers/easing";
@import "helpers/helpers";
@import "helpers/basicLightbox.min";
@import "helpers/_base.css";
@import "helpers/_choices.css";

/*********************
COMMON
*********************/
@import "common/grid";
@import "common/fonts";
@import "common/colors";
@import "common/buttons";
@import "common/animations";
@import "common/form";
@import "common/content";

/*********************
HEADER
*********************/
@import "components/header";

/*********************
MENU
*********************/
@import "components/menu";

/*********************
FOOTER
*********************/
@import "components/footer";

/*********************
PAGE
*********************/
@import "layouts/page";

/*********************
BLOCKS
*********************/
@import "blocks/block-head";
@import "blocks/block-head-2cols";
@import "blocks/block-illustration-text";
@import "blocks/block-stories";
@import "blocks/block-video";
@import "blocks/block-image";
@import "blocks/block-text";
@import "blocks/block-2-columns";
@import "blocks/block-key-numbers";
@import "blocks/block-page-end";
@import "blocks/block-quote";
@import "blocks/block-insights";
@import "blocks/block-solutions";
@import "blocks/block-team";
@import "blocks/block-jobs";
@import "blocks/block-problematic";
@import "blocks/block-perfomance";
@import "blocks/block-skills";
@import "blocks/block-form";


/*********************
PAGES
*********************/
@import "pages/home";
@import "pages/projects";
@import "pages/insights";
@import "pages/project";
@import "pages/common";
@import "pages/contact";
@import "pages/single";
@import "pages/error";
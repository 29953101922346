.block.block-key-numbers {
    background-color: $blue-1;
    padding: 100px 0;

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-1 {

        .column-8 {
            @include set-grid-prop(margin-left, 0, 1);

            h2 {
                color: $white;
                font-size: 36px;
                line-height: 46px;

                span {
                    display: block;
                }

                .l-2 {
                    margin-left: 4.641350211%;
                }
            }
        }
    }

    .r-2 {
        margin-top: 80px;

        .column-10 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            margin-top: 100px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-2 {
            margin-top: 60px;

            .column-10 {
                padding-left: 20px;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                .number-item {
                    margin-top: 50px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .number-item__bottom {
                        margin-top: 30px;
                        font-size: 14px;
                        line-height: 1.4;
                    }
                }

                + .column-10 {
                    margin-top: 50px;
                }
            }
        }
    }
}
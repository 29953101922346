.section-text {
    padding: 100px 0 80px 0;

    .r-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        [class^="column"] {
            float: none;
        }

        .column-8, .column-12 {
            @include set-grid-prop(margin-left, 0, 1);

            .text-content {
                h3 {
                    transform: translate(-50px, 0);
                }
            }
        }
    }


    @media screen and (max-width: $mobile){
        padding: 60px 0 0 0;

        .r-1 {
            width: calc(100% - 60px);
            margin: 0 auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .column-8, .column-12 {
                h3 {
                    transform: translate(0, 0);
                }
            }
        }
    }
}
.section-text.color-bkg-blue {
    background-color: $blue-1;

    .r-1 {
        .column-8, .column-12 {
            .text-content {
                h1, h2, h3, h4, p, ul, ol, li, li:before, a {
                    color: $white;
                }
            }
        }
    }

}
.section-text.color-bkg-white {
    background-color: $white;

    .r-1 {
        .column-8, .column-12 {
            .text-content {
                h1, h2, h3, h4, p, ul, ol, li, li:before, a {
                    color: $blue-1;
                }
            }
        }
    }
}
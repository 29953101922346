#contact {
    background-color: $blue-1;
    padding: 190px 0 80px 0;
    overflow: hidden;

    .background {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        .main {
            z-index: 10;
        }

        .main,
        .slave {
            position: absolute;
            top: 45%;
            right: -65%;
            transform: translate3d(0, -50%, 0.1px);

            svg {
                width: 1882px;
                height: 1712px;
            }
        }

        .slave {

            @for $i from 1 through 9
            {
                &.s-#{$i} {
                    z-index: 10 - $i;
                    transform: translate3d(calc(-50px * #{$i}), -50%, 0.1px);
                }
            }
        }
    }

    .row {
        z-index: 2;
    }

    .section-content {

        .r-1 {

            .column-4 {
                @include set-grid-prop(margin-left, 1, 1);

                h1 {
                    @include armin-grotesk-black(44px, 44px);
                    color: $white;
                    text-transform: uppercase;
                }

                .subtitle {
                    margin-top: 30px;
                    @include armin-grotesk-semi-bold(19px, 31px);
                    color: white;
                }
            }
        }

        .r-2 {
            margin-top: 60px;

            .column-8 {
                @include set-grid-prop(margin-left, 2, 2);

                .gform_fields {
                    display: flex;
                    flex-wrap: wrap;
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }

                .gfield {
                    width: calc(50% - 20px);
                    margin-bottom: 40px;

                    &:nth-child(even) {
                        margin-left: 40px;
                    }

                    &.full {
                        width: 100%;
                    }

                    textarea,
                    input[type="email"],
                    input[type="text"],
                    input[type="tel"] {
                        border: 1px solid $white;
                        border-radius: 8px;
                        height: 50px;
                        width: 100%;
                        padding: 0 10px;
                        background-color: $blue-1;
                        @include armin-grotesk-regular(18px, 30px);
                        color: $green;
                        transition: border-color 0.6s $easeOutQuart;
                    }

                    textarea {
                        padding: 20px 10px;
                        height: 240px;
                        resize: none;
                    }

                    &.gfield_error {
                        textarea,
                        input[type="email"],
                        input[type="text"],
                        input[type="tel"] {
                            border-color: $red;
                        }
                    }
                }

                .gfield_html {
                    margin-left: 0 !important;
                    @include armin-grotesk-semi-bold(14px, 24px);
                    color: $green;
                }

                .gform_footer {
                    justify-content: flex-start;
                }

                .gform_button {
                    height: 50px;
                    padding: 0 30px;
                    @include armin-grotesk-regular(13px, 13px);
                    text-transform: uppercase;
                    color: $purple;
                    padding-top: 5px;
                    background-color: $white;
                    border-radius: 30px;
                }

                .gform_validation_errors {
                    display: none !important;
                }

                .validation_message {
                    background-color: transparent;
                    color: $red;
                    @include armin-grotesk-regular(14px, 18px);
                    border: none;
                    padding-left: 0;
                    padding-bottom: 0;
                }

                .gform_ajax_spinner {
                    margin-left: 10px;
                }

                .gform_confirmation_wrapper  {
                    @include armin-grotesk-semi-bold(20px, 28px);
                    color: $white;
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 130px 0 40px 0;

        .background {
            transform: translate(100%, 0) scale(0.7);
        }

        .section-content {

            .r-1 {

                .column-4 {

                    h1 {
                        font-size: 25px;
                        line-height: 30px;
                    }

                    .subtitle {
                        margin-top: 20px;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }

            .r-2 {
                margin-top: 10px;
            }

            .bforms {
                margin-left: 0;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .tr {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px;

                    .c1 {
                        margin-bottom: 6px;
                        font-size: 14px;
                    }

                    input[type=text] {
                        height: 40px;
                        font-size: 14px;
                        line-height: 1.2;
                    }

                    textarea {
                        height: 120px;
                        font-size: 14px;
                        line-height: 1.2;
                        padding: 10px;
                    }
                }
            }
        }

        .legal__content {
            font-size: 11px;
            line-height: 18px;
        }

        #error {
            font-size: 13px;
        }

        .r-2 {
            .column-8 {
                margin-top: 40px;

                .gfield {
                    width: 100% !important;
                    margin: 20px 0 0 0 !important;

                    &:first-child {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
}
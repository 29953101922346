.section-numbers {
    background-color: $white;
    padding: 100px 0;

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-1 {

        h2 {
            color: $blue-1;
        }
    }

    .r-2 {
        margin-top: 80px;

        .column-10 {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 100px;

            &:first-child {
                margin-top: 0;
            }

            .number-item {
                .number-item__top {
                    .number-item__before {
                        color: $white;
                    }
            
                    .number-item__content {
                        color: $white;
                        -webkit-text-stroke-color: $blue-1;
                    }

                    .number-item__after {
                        color: $blue-1;
                    }
                }

                .number-item__bottom {
                    color: $blue-1;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0 30px 0;

        .r-2 {
            margin-top: 40px;

            .column-10 {
                padding-left: 20px;
                align-items: flex-start;
                flex-direction: column;

                .number-item {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
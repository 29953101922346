#home {
    //background-color: $purple;

    section {
        position: relative;
    }

    @import "home/section-hero";
    @import "home/section-intro";
    @import "home/section-expertise";
    @import "home/section-new";
    @import "home/section-about";
    @import "home/section-skills";
    @import "home/section-stories";
    @import "home/section-references";
    @import "home/section-partners";

}

@media screen and (max-width: $mobile) {
    #home {}
}
